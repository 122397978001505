* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
}

.homepage-wrapper {
  height: 100vh;
  width: 100%;
  background-image: url("../../Assets/Images/q&a.png");
  background-position: center;
}
.headerFaq {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}

.headerFaq h1 {
  color: #020d44;
  font-size: calc(30px + (70 - 30) * ((100vw - 769px) / (1920 - 769)));
  font-weight: 900;
  margin-top: 5rem;
  margin-left: 6%;
  font-family: "Montserrat", sans-serif;
  text-align: left;
}

.artwork {
  margin-top: -6rem;
  width: calc(55rem - 15rem);
  margin-left: calc(-50% - 20rem);
  z-index: -1;
}

.artworkMobile {
  display: none;
}

.faqs {
  width: 90%;
  margin: 0 auto;
  margin-top: 5rem;
  /* padding: 1rem; */
  padding-bottom: 10rem;
  padding-top: 2rem;
  text-align: start;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background-color: #fff;
}

.faqs .faq .faq-question {
  position: relative;
  /* font-size: 34px; */
  font-size: calc(15px + (34 - 15) * ((100vw - 769px) / (1920 - 769)));
  font-weight: 600;
  padding-right: 80px;
  color: #020d44;
  font-family: "Montserrat", sans-serif;
  transition: all 0.4s ease;
  border-bottom: 1px solid #052bf0;
}

.faqs .faq .faq-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  font-family: "Montserrat", sans-serif;
  background-image: url("../img/plusSign.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
  text-align: start;
}

.faqs .faq .faq-answer {
  color: #020d44;
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
  font-size: 20px;
  font-size: calc(10px + (20 - 10) * ((100vw - 769px) / (1920 - 769)));
  margin-left: 3rem;
  width: 70%;
  font-family: "Montserrat", sans-serif;
  text-align: start;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  transform: translateY(-50%) rotate(180deg);
  background-image: url("../img/minusSign.svg");
}

.faqs .faq.open .faq-answer {
  max-height: 400px;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .artwork {
    display: none;
  }

  .artworkMobile {
    display: inline-block;
    position: absolute;
    bottom: 9%;
    right: 8%;
    width: 300px;
    height: 180px;
  }

  .bcgFaq {
    background-image: none;
  }

  .headerFaq h1 {
    font-size: 42px;
    margin-top: 1rem;
    margin-left: 2.9rem;
    width: 100%;
  }

  .faqs {
    margin-top: -1rem;
  }
  /* .faq-question  {
        font-size: 18px;
    }
     */

  .faqs {
    padding-bottom: 1rem;
    padding-top: 5rem;
    text-align: start;
  }

  .faqs .faq .faq-question {
    font-size: 16px;
  }

  .faqs .faq .faq-question::after {
    top: 25%;
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 426px) {
  .homepage-wrapper {
    margin-top: 50px;
  }

  .headerFaq {
    padding-left: 0;
  }
  .artwork {
    display: none;
  }

  .artworkMobile {
    display: inline-block;
    width: 180px;
    height: 100px;
    bottom: 8%;
  }

  .headerFaq h1 {
    font-size: 42px;
    margin-top: -1rem;
    margin-left: 2.5rem;
  }

  .faqs {
    margin-top: -1rem;
  }
  .faq-question {
    font-size: 18px !important;
  }

  .faq-answer {
    font-size: 12px !important;
  }

  /* .faqs .faq .faq-question::after {
    top: 10%;

    background-image: url("../img/plusSign.svg");
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;
    transition: all 0.4s ease-out;
  } */

  .faqs .faq .faq-question::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    background-size: 20px;
  }
}
@media screen and (max-width: 376px) {
  .homepage-wrapper {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -70px;
  }
  .headerFaq {
    text-align: left;
    margin-top: 100px;
    margin-left: 40px;
    width: 80%;
    padding-bottom: 0;
  }

  .artworkMobile {
    display: inline-block;
    width: 200px;
    height: 120px;
    bottom: 3%;
  }

  .headerFaq h1 {
    font-size: 30px;
    margin-left: -10px;
  }

  .faqs {
    padding-top: 20px;
    margin-left: 0px;
    width: 100%;
  }

  .faqs .faq .faq-question {
    font-size: 18px;
    padding-right: 20px;
    width: 100%;
  }

  .faqs .faq .faq-answer {
    margin-left: 0;
    font-size: 14px;
    width: 100%;
  }

  .faqs .faq .faq-question::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    background-size: 18px;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1699px) {
}
@media only screen and (min-width: 1501px) and (max-width: 1599px) {
}
@media only screen and (min-width: 1431px) and (max-width: 1500px) {
  .headerFaq h1 {
    margin-top: 5rem;
  }
  .faqs .faq .faq-question::after {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 1365px) and (max-width: 1430px) {
  .headerFaq h1 {
    margin-top: 1rem;
  }
  .faqs .faq .faq-question::after {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 1267px) and (max-width: 1364px) {
  .headerFaq h1 {
    margin-top: 1rem;
  }
  .faqs .faq .faq-question::after {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 1060px) and (max-width: 1266px) {
  .headerFaq h1 {
    margin-top: 1rem;
  }
  .faqs .faq .faq-question::after {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (max-width: 769px) {
}
@media only screen and (max-width: 426px) {
}
@media only screen and (max-width: 376px) {
}
