.filterButton {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  margin-bottom: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.filterButton button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
}

.filterButton:hover {
  font-weight: bold;
}

.filterButton:focus,
.filterButton button:focus {
  outline: none;
}
.box-sm {
  min-height: 15px;
  min-width: 15px;
  border: 1px solid gray;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark {
  font-size: 13px;
  color: blue;
}

.graycheck {
  font-size: 13px;
  color: lightgray;
}

@media only screen and (max-width: 1805px) {
  .filterButton button {
    font-size: 14px;
  }

  .filterButton {
    font-size: 14px;
  }
}

/* @media only screen and (max-width: 1600px) {
  .filterButton {
    font-size: 13px;
  }
} */

@media only screen and (min-width: 1600px) and (max-width: 1699px) {
}

@media only screen and (min-width: 1501px) and (max-width: 1599px) {
  .filterButton button {
    font-size: 16px;
  }
  .filterButton {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1431px) and (max-width: 1500px) {
  .filterButton {
    font-size: 15px;
  }
  .filterButton button {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1365px) and (max-width: 1430px) {
  .filterButton {
    font-size: 14px;
  }
  .filterButton button {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1267px) and (max-width: 1364px) {
  .filterButton {
    font-size: 13px;
    margin-bottom: 20px;
  }
  .filterButton button {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1060px) and (max-width: 1266px) {
  .filterButton {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .filterButton button {
    font-size: 14px;
  }
}

@media only screen and (max-width: 769px) {
  .filterButton {
    font-size: 18px;
    margin-bottom: 20px;
    margin-left: 2px;
  }
  .filterButton button {
    font-size: 18px;
  }

  .checkmark {
    font-size: 12px;
  }
}

@media only screen and (max-width: 426px) {
  .box-sm {
    min-height: 22px;
    min-width: 22px;
  }

  .checkmark {
    font-size: 14px;
  }

  .filterButton {
    font-size: 16px;
    margin-bottom: 7px;
    margin-left: 0px;
  }
  .filterButton button {
    font-size: 16px;
  }
}

@media only screen and (max-width: 376px) {
  .filterButton {
    font-size: 15px;
    margin-bottom: 7px;
  }
  .filterButton button {
    font-size: 15px;
  }
}
