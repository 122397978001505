* {
  padding: 0;
  margin: 0;
}

.backgroundServices {
  height: 100vh;
  background: hsla(0, 0%, 92%, 0.1);
  /* background-image: radial-gradient(hsla(342, 100%, 52%, 1) 10%, transparent 0); */
  background-image: url("../img/Repeat\ Grid\ 8.png");

  background-size: contain;
  font-family: "Montserrat", sans-serif;
  position: relative;
  overflow: hidden;
}

.iScrollVerticalScrollbar,
.iScrollLoneScrollbar {
  display: none;
}

.servicesTitle {
  color: #ff0b53;
  text-align: center;
  /* margin-top: 55px; */
  height: calc(5% - 5px);
  font-size: 24px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  z-index: 1;
}

.services {
  padding: 10rem;
  margin-top: -45px;
  /* display: flex;
  justify-content: space-between; */
}
.keywords {
  display: flex;
  justify-content: space-between;
  font-size: calc(15px + (64 - 15) * ((100vw - 769px) / (1920 - 769)));
  /* font-size: 3.5rem; */
  color: #052bf0;
  /* line-height: 78px; */
  margin-top: 40px;
  /* width: calc(90% - 15px); */
  /* margin-left: 1rem;
  margin-right: 1rem; */
  font-weight: 550;
  text-align: center;
}

.buttonParent {
  height: 10rem;
  display: flex;
  justify-content: center;
  margin-top: calc(-3% - 5px);
}

.hireMarketier {
  width: calc(18% - 5px);
  /* margin-left: calc(1% - 15px); */
  height: 65px;
  /* height: calc(5% - 5px); */
  background-color: #052bf0;
  color: white;
  /* font-size: 24px; */
  font-size: calc(15px + (24 - 15) * ((100vw - 769px) / (1920 - 769)));
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.hireMarketier:hover {
  background-color: #ff0b53;
}

@media screen and (max-width: 768px) {
  .servicesTitle {
    margin-top: -1rem;
    font-size: 20px;
  }
  .services {
    padding: 5rem;
  }
  .keywords {
    font-size: 32px;
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
  .buttonParent {
    margin-top: -50px;
    width: 100%;
  }

  .hireMarketier {
    width: 280px;
    height: 48px;
    background-color: #052bf0;
    color: white;
    font-size: 20px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    border: none;
  }
}

@media screen and (max-width: 426px) {
  .services {
    padding: 3rem;
  }
  .keywords {
    font-size: 22px;
    margin-top: 5rem;
    width: 100%;
  }

  .hireMarketier {
    width: 280px;
    height: 48px;
    background-color: #052bf0;
    color: white;
    font-size: 20px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
  }

  .buttonParent {
    margin-top: 0;
  }

  .servicesTitle {
    margin-top: 3rem;
    font-size: 18px;
  }
}
@media screen and (max-width: 376px) {
  .backgroundServices {
    margin-top: 0px;
  }
  .servicesTitle {
    font-size: 16px;
    margin-top: 70px;
    font-size: 12px;
    width: 100%;
  }

  .services {
    padding: 2rem;
  }

  .keywords {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 22px;
    color: #052bf0;
    /* line-height: 27px; */
    margin-top: 30px;
  }

  .hireMarketier {
    width: 280px;
    height: 48px;
    background-color: #052bf0;
    color: white;
    font-size: 20px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    border: none;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1699px) {
  .buttonParent {
    height: 10rem;
    display: flex;
    justify-content: center;
    margin-top: calc(-2% - 5px);
  }
  .hireMarketier {
    height: 60px;
  }
}
@media only screen and (min-width: 1501px) and (max-width: 1599px) {
  .buttonParent {
    height: 10rem;
    display: flex;
    justify-content: center;
    margin-top: calc(-5% - 5px);
  }
  .hireMarketier {
    height: 55px;
  }
}
@media only screen and (min-width: 1431px) and (max-width: 1500px) {
  .buttonParent {
    height: 10rem;
    display: flex;
    justify-content: center;
    margin-top: calc(-5% - 5px);
  }
  .hireMarketier {
    height: 45px;
  }
}
@media only screen and (min-width: 1365px) and (max-width: 1430px) {
  .buttonParent {
    height: 10rem;
    display: flex;
    justify-content: center;
    margin-top: calc(-4% - 5px);
  }
  .hireMarketier {
    height: 45px;
  }
}
@media only screen and (min-width: 1267px) and (max-width: 1364px) {
  .buttonParent {
    height: 10rem;
    display: flex;
    justify-content: center;
    margin-top: calc(-6% - 5px);
  }
  .hireMarketier {
    height: 45px;
  }
}
@media only screen and (min-width: 1060px) and (max-width: 1266px) {
  .buttonParent {
    height: 10rem;
    display: flex;
    justify-content: center;
    margin-top: calc(-7% - 5px);
  }
  .hireMarketier {
    height: 40px;
  }
}
@media only screen and (max-width: 769px) {
}
@media only screen and (max-width: 426px) {
}
@media only screen and (max-width: 376px) {
}
