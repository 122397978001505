@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap);
html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: "Montserrat", sans-serif;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

body {
  position: relative;
  background-color: white;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* .App {
  text-align: center;
} */


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.footer {
  background-color: #020d44;
  width: 100%;
  height: 100vh;
  font-family: "Montserrat", sans-serif;
  position: relative;
  text-align: left;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-bottom: 50px;
}

/* .footer-padding-down {
  padding-top: 60px;
} */
ul li {
  list-style-type: none;
}
a {
  text-decoration: none;
  cursor: pointer;
}
/* .title-footer {
  height: 80vh;
} */
.title-footer h1 {
  padding: 0;
  margin: 0;
  margin-left: 90px;
  padding-top: 177px;
  color: white;
  font-size: calc(30px + (70 - 30) * ((100vw - 769px) / (1920 - 769)));
  font-weight: 900;
  line-height: 91px;
}
.display-flex {
  display: -webkit-flex;
  display: flex;
  /* margin-top: 400px; */
  /* margin-top: calc(20% + 60px); */
}
.social-icon {
  padding-left: 5%;
  width: 33%;
  margin-top: 1%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.social-icon ul {
  padding-top: 40px;
  display: -webkit-flex;
  display: flex;
}
.social-icon ul li {
  margin-left: 20px;
}
.contact-info ul li {
  color: white;
  margin-left: 2%;
  font-weight: bold;
  font-size: 34px;
  font-size: calc(18px + (34 - 18) * ((100vw - 769px) / (1920 - 769)));
  line-height: 74px;
}
.fbIcon {
  margin-top: -9px;
  margin-left: 15%;
}
.linkedinIcon {
  margin-top: -5px;
}
.contact-info {
  /* margin-left: 9%; */
  margin-top: -2%;
  width: 33%;
}
.social-media {
  color: white;
  /* margin-left: 9%; */
  margin-top: -9.5%;
  width: 33%;
}
.social-media ul li a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  /* font-size: 34px; */
  font-size: calc(18px + (34 - 18) * ((100vw - 769px) / (1920 - 769)));
  line-height: 74px;
}

#footermarketer {
  text-decoration: underline;
}
.img1 {
  background-image: url(/static/media/FooterElement.f099396e.png);
  padding: 20%;
  top: 0;
  right: 0.5%;
  background-repeat: no-repeat;
  position: absolute;
}
.footerapliciraj-tel,
.footermarketer-tel {
  display: none;
}
/* @media (max-width: 768px) {
  .footermarketer-pc,
  .footerapliciraj-pc {
    display: none;
  }
  .footermarketer-tel {
    text-decoration: underline;
  }
  .footerapliciraj-tel,
  .footermarketer-tel {
    display: block;
  }
}
@media (max-width: 768px) {
  .display-flex {
    display: flex;
  }
  .footer {
    width: 100vw;
    height: 100vh;
    background-color: #020d44;
  }
  .title-footer h1 {
    padding-top: 0;
  }
  .social-icon {
    padding-top: 17%;
    padding-left: 9%;
  }
  .social-media {
    margin-top: -28%;
    margin-left: -21%;
    width: 41vw;
  }
  .social-media ul li a {
    line-height: 48px;
  }
  .contact-info {
    margin-top: 0%;
    margin-left: -41%;
  }
  .contact-info ul li {
    line-height: 48px;
  }
  #footermarketer1 {
    text-decoration: underline;
  }
}

@media (max-width: 426px) {
  .footer {
    width: 100vw;
    height: 100vh;
    background-color: #020d44;
  }
  
  #footermarketer1 {
    text-decoration: underline;
  }
  .img1 {
    display: none;
  }
  
  .social-media {
    margin-top: -37%;
    margin-left: -28%;
    width: 100vw;
    height: max-content;
  }
  .social-media ul li a {
    line-height: 48px;
  }
  .contact-info {
    padding-top: 6%;
    width: 100vw;
    height: max-content;
    margin-left: -97%;
  }
  .contact-info ul li {
    line-height: 48px;
  }

  .social-icon {
    width: 35vw;
    height: max-content;
    padding-top: 30%;
    padding-left: 1%;
  }
  .title-footer h1 {
    padding: 0;
    margin: 0;
    color: white;
    font-size: 70px;
  }
}
@media (max-width: 426px) {
  .contact-info {
    padding-top: 12%;
    width: 100vw;
    height: max-content;
    margin-left: -97%;
  }
  .social-icon {
    width: 44vw;
    height: max-content;
    padding-top: 30%;
    padding-left: 1%;
  }
  .social-media {
    margin-top: -37%;
    margin-left: -36%;
    width: 100vw;
    height: max-content;
  }
} */
@media (max-width: 770px) {
  .img1 {
    display: none;
  }
  .footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0;
    padding: 10px;
    padding-bottom: 100px;
  }

  .footer-padding-down {
    padding-top: 80px;
  }

  .footer h1 {
    margin: 0;
    padding: 0;
  }

  .display-flex {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0;
    margin: 0;
    height: 100%;
  }

  .social-media {
    color: white;
    -webkit-order: 1;
            order: 1;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-top: 10%;
  }

  .contact-info {
    -webkit-order: 2;
            order: 2;
    width: 100%;
    margin-top: 10%;
  }

  .social-icon {
    -webkit-order: 3;
            order: 3;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-top: 12%;
  }

  .contact-info ul li {
    margin: 0;
    line-height: 40px;
  }
  .social-icon ul li {
    margin-left: 0;
    margin-right: 20px;
  }
  .social-media ul li a {
    line-height: 50px;
  }

  .social-icon ul {
    padding-top: 0;
  }
}

@media (max-width: 426px) {
  .footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0;
    padding: 10px;
    height: 100vh;
    padding-bottom: 110px;
  }

  .footer-padding-down {
    padding-top: 70px;
    padding-bottom: 30px;
  }

  .footer h1 {
    margin: 0;
    padding: 0;
  }

  .display-flex {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    padding: 0;
    margin: 0;
    height: 80%;
  }

  .social-media {
    color: white;
    -webkit-order: 1;
            order: 1;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .contact-info {
    -webkit-order: 2;
            order: 2;
    width: 100%;
  }

  .social-icon {
    -webkit-order: 3;
            order: 3;
    padding: 0;
    margin: 0;
    width: 50%;
  }

  .contact-info ul li {
    margin: 0;
  }
  .social-icon ul li {
    margin-left: 0;
    margin-right: 20px;
  }
  .social-media ul li a {
    line-height: 50px;
  }
  .social-icon ul {
    padding-top: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 100px;
  }

  .footer h1 {
    font-size: 34px;
  }

  .social-media {
    margin-top: 30px;
  }

  .contact-info ul li {
    font-size: 20px;
  }

  .social-media ul li a {
    font-size: 20px;
  }

  .social-media {
    margin-top: 00px;
  }

  .contact-info {
    margin-top: -18%;
  }

  .social-icon ul {
    margin-top: -100px;
  }
}

@media (max-width: 376px) {
  .footer-padding-down {
    padding-top: 70px;
    padding-bottom: 30px;
  }

  .contact-info ul li {
    line-height: 40px;
  }

  .social-icon {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }

  .social-icon ul {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 100px;
    margin-top: -120px;
  }

  .social-media {
    margin-top: 0;
  }

  .footer h1 {
    font-size: 34px;
  }

  .social-media {
    margin-top: 30px;
  }

  .contact-info ul li {
    font-size: 20px;
  }

  .social-media ul li a {
    font-size: 20px;
  }

  .footer {
    padding-bottom: 55px;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    position: relative;
  }

  .title-footer {
    position: absolute;
    top: 3%;
  }

  .display-flex {
    position: absolute;
    top: 10%;
    -webkit-justify-content: safe;
            justify-content: safe;
    height: 76%;
  }

  .social-icon ul li {
    margin-top: 10px;
    margin-right: 12px;
  }

  .footer-padding-down .display-flex {
    position: absolute;
    top: 14%;
  }
  .footer-padding-down .title-footer {
    position: absolute;
    top: 9%;
  }
}
/* ovde treba proverka za 1600 i 1501!! */
@media only screen and (min-width: 1600px) and (max-width: 1699px) {
  .social-media {
    color: white;
    /* margin-left: 9%; */
    margin-top: -10.5%;
    width: 33%;
  }
}
@media only screen and (min-width: 1501px) and (max-width: 1599px) {
  .social-media {
    color: white;
    /* margin-left: 9%; */
    margin-top: -12.5%;
    width: 33%;
  }

  .social-icon {
    margin-top: -2%;
  }
  /* ovde treba proverka za 1600 i 1501!! */
}
@media only screen and (min-width: 1431px) and (max-width: 1500px) {
  .title-footer h1 {
    padding: 0;
    margin: 0;
    margin-left: 90px;
    padding-top: 137px;
    line-height: 81px;
  }
  .social-media {
    color: white;
    /* margin-left: 9%; */
    margin-top: -12.5%;
    width: 33%;
  }
}
@media only screen and (min-width: 1365px) and (max-width: 1430px) {
  .title-footer h1 {
    padding: 0;
    margin: 0;
    margin-left: 90px;
    padding-top: 120px;
    line-height: 81px;
  }
  .social-media {
    color: white;
    /* margin-left: 9%; */
    margin-top: -12.5%;
    width: 33%;
  }
}
@media only screen and (min-width: 1267px) and (max-width: 1364px) {
  .title-footer h1 {
    padding: 0;
    margin: 0;
    margin-left: 90px;
    padding-top: 50px;
    line-height: 81px;
  }
  .social-media {
    color: white;
    /* margin-left: 9%; */
    margin-top: -12.5%;
    width: 33%;
  }
}
@media only screen and (min-width: 1060px) and (max-width: 1266px) {
  .title-footer h1 {
    padding: 0;
    margin: 0;
    margin-left: 90px;
    padding-top: 50px;
    line-height: 81px;
  }
  .social-media {
    color: white;
    /* margin-left: 9%; */
    margin-top: -15.5%;
    width: 33%;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
}

.homepage-wrapper {
  height: 100vh;
  width: 100%;
  background-image: url(/static/media/q&a.29828349.png);
  background-position: center;
}
.headerFaq {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 2rem;
}

.headerFaq h1 {
  color: #020d44;
  font-size: calc(30px + (70 - 30) * ((100vw - 769px) / (1920 - 769)));
  font-weight: 900;
  margin-top: 5rem;
  margin-left: 6%;
  font-family: "Montserrat", sans-serif;
  text-align: left;
}

.artwork {
  margin-top: -6rem;
  width: calc(55rem - 15rem);
  margin-left: calc(-50% - 20rem);
  z-index: -1;
}

.artworkMobile {
  display: none;
}

.faqs {
  width: 90%;
  margin: 0 auto;
  margin-top: 5rem;
  /* padding: 1rem; */
  padding-bottom: 10rem;
  padding-top: 2rem;
  text-align: start;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background-color: #fff;
}

.faqs .faq .faq-question {
  position: relative;
  /* font-size: 34px; */
  font-size: calc(15px + (34 - 15) * ((100vw - 769px) / (1920 - 769)));
  font-weight: 600;
  padding-right: 80px;
  color: #020d44;
  font-family: "Montserrat", sans-serif;
  transition: all 0.4s ease;
  border-bottom: 1px solid #052bf0;
}

.faqs .faq .faq-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 30px;
  height: 30px;
  font-family: "Montserrat", sans-serif;
  background-image: url(/static/media/plusSign.787f5146.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
  text-align: start;
}

.faqs .faq .faq-answer {
  color: #020d44;
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
  font-size: 20px;
  font-size: calc(10px + (20 - 10) * ((100vw - 769px) / (1920 - 769)));
  margin-left: 3rem;
  width: 70%;
  font-family: "Montserrat", sans-serif;
  text-align: start;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
  background-image: url(/static/media/minusSign.f9012338.svg);
}

.faqs .faq.open .faq-answer {
  max-height: 400px;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .artwork {
    display: none;
  }

  .artworkMobile {
    display: inline-block;
    position: absolute;
    bottom: 9%;
    right: 8%;
    width: 300px;
    height: 180px;
  }

  .bcgFaq {
    background-image: none;
  }

  .headerFaq h1 {
    font-size: 42px;
    margin-top: 1rem;
    margin-left: 2.9rem;
    width: 100%;
  }

  .faqs {
    margin-top: -1rem;
  }
  /* .faq-question  {
        font-size: 18px;
    }
     */

  .faqs {
    padding-bottom: 1rem;
    padding-top: 5rem;
    text-align: start;
  }

  .faqs .faq .faq-question {
    font-size: 16px;
  }

  .faqs .faq .faq-question::after {
    top: 25%;
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 426px) {
  .homepage-wrapper {
    margin-top: 50px;
  }

  .headerFaq {
    padding-left: 0;
  }
  .artwork {
    display: none;
  }

  .artworkMobile {
    display: inline-block;
    width: 180px;
    height: 100px;
    bottom: 8%;
  }

  .headerFaq h1 {
    font-size: 42px;
    margin-top: -1rem;
    margin-left: 2.5rem;
  }

  .faqs {
    margin-top: -1rem;
  }
  .faq-question {
    font-size: 18px !important;
  }

  .faq-answer {
    font-size: 12px !important;
  }

  /* .faqs .faq .faq-question::after {
    top: 10%;

    background-image: url("../img/plusSign.svg");
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;
    transition: all 0.4s ease-out;
  } */

  .faqs .faq .faq-question::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-size: 20px;
  }
}
@media screen and (max-width: 376px) {
  .homepage-wrapper {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -70px;
  }
  .headerFaq {
    text-align: left;
    margin-top: 100px;
    margin-left: 40px;
    width: 80%;
    padding-bottom: 0;
  }

  .artworkMobile {
    display: inline-block;
    width: 200px;
    height: 120px;
    bottom: 3%;
  }

  .headerFaq h1 {
    font-size: 30px;
    margin-left: -10px;
  }

  .faqs {
    padding-top: 20px;
    margin-left: 0px;
    width: 100%;
  }

  .faqs .faq .faq-question {
    font-size: 18px;
    padding-right: 20px;
    width: 100%;
  }

  .faqs .faq .faq-answer {
    margin-left: 0;
    font-size: 14px;
    width: 100%;
  }

  .faqs .faq .faq-question::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-size: 18px;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1699px) {
}
@media only screen and (min-width: 1501px) and (max-width: 1599px) {
}
@media only screen and (min-width: 1431px) and (max-width: 1500px) {
  .headerFaq h1 {
    margin-top: 5rem;
  }
  .faqs .faq .faq-question::after {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 1365px) and (max-width: 1430px) {
  .headerFaq h1 {
    margin-top: 1rem;
  }
  .faqs .faq .faq-question::after {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 1267px) and (max-width: 1364px) {
  .headerFaq h1 {
    margin-top: 1rem;
  }
  .faqs .faq .faq-question::after {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 1060px) and (max-width: 1266px) {
  .headerFaq h1 {
    margin-top: 1rem;
  }
  .faqs .faq .faq-question::after {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (max-width: 769px) {
}
@media only screen and (max-width: 426px) {
}
@media only screen and (max-width: 376px) {
}

* {
  margin: 0;
  padding: 0;
}

.hero-wrap {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

/* .hero {
  position: absolute;
  right: 0;
  height: 100vh;
} */

.hero-wrap {
  background-image: url(/static/media/Hero.2dce5a68.png);
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
}

.heroMobile {
  display: none;
}

.heroTablet {
  display: none;
}

.hero-wrap h1 {
  position: absolute;
  top: 27%;
  text-align: left;
  left: 6%;
  font-size: 70px;
  font-size: calc(30px + (70 - 30) * ((100vw - 769px) / (1920 - 769)));
  font-weight: 900;
  color: #020d44;
}

.hero-wrap p {
  position: absolute;
  /* font-size: 30px; */
  font-size: calc(18px + (28 - 18) * ((100vw - 769px) / (1920 - 769)));
  font-weight: 900;
  text-align: left;
  top: 51%;
  left: 6%;
  width: 37%;
  font-weight: 400;
  color: #020d44;
}

.btn-space {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 55px;
  width: 40%;
  background-color: cadetblue;
  bottom: 25%;
  left: 6%;
  border: none;
  background-color: transparent;
}

.buttonHireMarketier {
  background-color: #052bf0;
  color: white;
  font-size: calc(15px + (24 - 15) * ((100vw - 769px) / (1920 - 769)));
  width: 48%;
  font-weight: 600;
  border: none;
  cursor: pointer;
  text-align: center;
  word-break: keep-all;
  white-space: nowrap;
}

.buttonHireMarketier:hover {
  background-color: #ff0b53;
}
.buttonApplyAsMarketier {
  background-color: white;
  color: #020d44;
  font-size: calc(15px + (24 - 15) * ((100vw - 769px) / (1920 - 769)));
  text-align: center;
  border: none;
  cursor: pointer;
  width: 44%;
  word-break: keep-all;
  white-space: nowrap;
}

@media only screen and (max-width: 769px) {
  .hero {
    display: none;
  }

  .hero-wrap {
    background-image: none;
    overflow: hidden;
  }
  .heroTablet {
    display: block;
    position: absolute;
    bottom: 55px;
    right: 0;
    height: 100vh;
  }

  .heroMobile {
    display: none;
  }

  .btn-space {
    left: 6%;
    bottom: 30%;
    width: 62%;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 110px;
  }
  .buttonHireMarketier {
    font-size: 18px;
    font-weight: 700;
    height: 50px;
  }

  .buttonApplyAsMarketier {
    font-size: 18px;
    font-weight: 700;
    height: 50px;
    width: 48%;
  }
  .hero-wrap h1 {
    position: absolute;
    top: 27%;
    text-align: left;
    left: 6%;
    font-size: 45px;
    font-weight: 900;
    color: #020d44;
  }

  .hero-wrap p {
    position: absolute;
    font-size: 20px;
    font-weight: 900;
    text-align: left;
    top: 51%;
    left: 6%;
    width: 80%;
    font-weight: 350;
    color: #020d44;
  }
  .AppLanding p {
    margin-left: 0;
  }
}

@media screen and (max-width: 426px) {
  .backgroundServices {
    padding-bottom: 20px;
  }
  .hero {
    display: none;
  }
  .hero-wrap {
    margin-top: -30%;
    height: 100vh;
  }

  .heroTablet {
    display: none;
  }
  .heroMobile {
    display: block;
    position: absolute;
    bottom: 0px;
    right: 0;
  }
  /* .AppLanding h1 {
    margin-left: 50px;
    margin-top: -75vh;
    font-size: 34px;
    width: 70%;
  } */

  .AppLanding p {
    /* margin-left: 50px; */
    margin-top: 20px;
    font-size: 18px;
    font-weight: 900;
    width: 80%;
    font-weight: 200;
  }
  .buttonHireMarketier {
    height: 50px;
    width: 100%;
  }

  .btn-space {
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 90px;
    bottom: 23%;
  }

  /* .Landing {
    background-image: url("./herom.png");
    height: 100vh;
    background-size: cover;
  } */
  #arrowAnim {
    width: 20px;
    height: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: absolute;
    top: 45%;
    right: -21px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  .arrow {
    width: 5vw;
    height: 5vw;
    border: 2.5vw solid;
    border-color: #020d44 transparent transparent #020d44;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }

  .arrowSliding {
    position: absolute;
    -webkit-animation: slide 4s linear infinite;
    animation: slide 4s linear infinite;
  }

  .delay1 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  .delay2 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
  .delay3 {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
  }

  @-webkit-keyframes slide {
    0% {
      opacity: 0;
      -webkit-transform: translateX(15vw);
              transform: translateX(15vw);
    }
    20% {
      opacity: 1;
      -webkit-transform: translateX(9vw);
              transform: translateX(9vw);
    }
    80% {
      opacity: 1;
      -webkit-transform: translateX(-9vw);
              transform: translateX(-9vw);
    }
    100% {
      opacity: 0;
      -webkit-transform: translateX(-15vw);
              transform: translateX(-15vw);
    }
  }
  @keyframes slide {
    0% {
      opacity: 0;
      -webkit-transform: translateX(15vw);
              transform: translateX(15vw);
    }
    20% {
      opacity: 1;
      -webkit-transform: translateX(9vw);
              transform: translateX(9vw);
    }
    80% {
      opacity: 1;
      -webkit-transform: translateX(-9vw);
              transform: translateX(-9vw);
    }
    100% {
      opacity: 0;
      -webkit-transform: translateX(-15vw);
              transform: translateX(-15vw);
    }
  }
}

.buttonApplyAsMarketier:hover {
  color: #052bf0;
}

@media screen and (max-width: 376px) {
  .hero-wrap h1 {
    font-size: 38px;
  }

  .hero-wrap p {
    top: 35%;
  }

  .hero-wrap h1 {
    top: 15%;
  }

  .buttonApplyAsMarketier {
    width: 260px;
    bottom: 30%;
  }

  .btn-space {
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100px;
    bottom: 30%;
  }

  .heroMobile {
    height: auto;
    position: absolute;
    bottom: 15px;
    right: 0;
  }
}

.apply-as-a-marketer {
  padding: 0 24px;
  background-color: #020d44;
  border: 1px solid #052bf0;
}

.apply-as-a-marketer:hover {
  background-color: transparent;
}

.apply-as-a-marketer a {
  color: white !important;
}
.apply-as-a-marketer a:hover {
  color: #052bf0 !important;
}

@media (max-width: 769px) {
  .apply-as-a-marketer {
    border: none;
    text-decoration: underline;
    background-color: white;
    border: none !important;
  }
  .apply-as-a-marketer a {
    color: #020d44 !important ;
  }
  .apply-as-a-marketer a:hover {
    border: none;
  }
}

.hire-marketer {
  padding: 0 24px;
  background-color: #052bf0;
  border: 1px solid #052bf0;
  margin-right: 5px;
  margin-left: 5px;
}

.hire-marketer:hover {
  background-color: #ff0b53;
  border: 1px solid #ff0b53;
}

.hire-marketer a {
  color: white !important;
}

@media (max-width: 767px) {
  .hire-marketer:hover {
    background-color: #052bf0;
  }
  .hire-marketer {
    border: none !important;
  }
  .hire-marketer-mobile {
    margin-bottom: 30px;
  }
}

@media (max-width: 426px) {
  .hire-marketer-mobile {
    width: 280px;
    height: 48px;
    margin: 0 auto;
    font-size: 20px;
  }

  .hire-marketer-mobile a {
    margin-right: 0;
  }
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

/* a {
  color: #000;
} */

.header {
  height: 90px;
  /* background-color: #fff; */
  width: 100%;
  z-index: 22;
  position: absolute;
  top: 0;
  /* box-shadow: 0 8px 15px 0px rgba(0, 0, 0, 0.2); */
}

.header ul {
  margin: 0;
  padding-top: 30px;
  list-style: none;
  overflow: hidden;
  /* background-color: #fff; */
  padding-right: 40px;
}

.header li a {
  color: #020d44;
  font-weight: bold;
  display: block;
  padding: 20px 20px;
  text-decoration: none;
  font-size: 14px;
}
.header li a:hover {
  color: #052bf0;
}

.header .logo {
  float: left;
  text-decoration: none;
  margin-top: 24px;
  margin-left: 100px;
}

.logo img {
  height: 55px;
  width: 55px;
}

.menu {
  display: -webkit-flex;
  display: flex;
}
/* 
.navIMG {
  display: none;
} */

.header .menu {
  z-index: 99999999999;
  /* text-align: center; */
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 40px 60px;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 35px;
}

.header .menu-icon .navicon {
  top: 5px;
  height: 3px;
  width: 35px;
}
.header .menu-icon .navicon:before {
  top: 10px;
}

.header .menu-icon .navicon:after {
  top: -10px;
}

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  padding-bottom: 60px;
  max-height: 100vh;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.header .menu-btn:checked ~ img {
  height: 20vh;
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

.hidden-btn {
  display: none;
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .header .logo {
    margin-left: 80px;
  }
}

@media (min-width: 900px) and (max-width: 1400px) {
  .header .logo {
    margin-left: 70px;
  }
}

@media (max-width: 918px) {
  .header li a {
    font-size: 10px;
  }
}
@media (max-width: 873px) {
  .header li a {
    font-size: 10px;
  }
  .header ul {
    padding-right: 25px;
  }
}
@media (max-width: 817px) {
  .header li a {
    font-size: 10px;
  }
}
@media (max-width: 808px) {
  .header li a {
    /* font-size: 19px; */
  }
  .header ul {
    padding-right: 20px;
  }
}

@media (max-width: 802px) {
  .header li a {
    /* font-size: 14px; */
  }
}

@media (min-width: 770px) {
  .header li {
    float: left;
  }
  .header li a {
    padding: 10px 5px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
  /* .header .logo {
    margin-left: 40px;
  } */
}

.desktop-menu {
  display: block;
  display: -webkit-flex;
  display: flex;
}

.mobile-menu {
  display: none;
}

@media (max-width: 769px) {
  .header {
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 8px 15px 0px rgba(0, 0, 0, 0.2);
  }

  .header ul {
    background-color: white;
    padding-top: 0;
  }

  .header li a {
    text-align: center;
    font-size: 20px;
  }
  .menu li {
    margin-top: 50px;
  }
  .hidden-btn {
    display: block;
    width: -webkit-max-content;
    width: max-content;
  }
  .desktop-menu {
    display: none;
  }
  .mobile-menu {
    display: block;
  }
  .menu {
    display: block;
    height: 100vh;
  }
  .hidden {
    display: none;
  }
  .header li a {
    font-size: 20px;
  }
  .header .menu {
    padding: 0px 60px 0px 60px;
  }

  .header .menu-icon {
    padding: 40px 80px;
  }

  .hire-marketer {
    padding: 0 24px;
    background-color: #052bf0;
    border: 1px solid #052bf0;
  }

  .apply-as-a-marketer {
    padding: 0 24px;
    background-color: white;
    color: black;
    border: 1px solid #052bf0;
  }
}

@media (max-width: 426px) {
  .header {
    height: 70px;
  }
  .header .menu {
    padding: 0;
    padding-bottom: 0px;
  }
  .header .logo {
    margin-left: 30px;
    margin-top: 10px;
  }
  .header .menu-icon {
    padding: 28px 30px;
  }

  .header li a {
    font-size: 16px;
    padding: 15px 20px;
    text-decoration: none;
    margin-bottom: 20px;
    word-break: keep-all;
    white-space: nowrap;
  }

  .menu {
    position: relative;
    background-image: url(/static/media/menupic.961accd2.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom left;
  }
}

@media (max-width: 376px) {
  .menu li {
    margin-top: 35px;
  }
}

* {
  padding: 0;
  margin: 0;
}

.backgroundServices {
  height: 100vh;
  background: hsla(0, 0%, 92%, 0.1);
  /* background-image: radial-gradient(hsla(342, 100%, 52%, 1) 10%, transparent 0); */
  background-image: url("/static/media/Repeat Grid 8.582bc39b.png");

  background-size: contain;
  font-family: "Montserrat", sans-serif;
  position: relative;
  overflow: hidden;
}

.iScrollVerticalScrollbar,
.iScrollLoneScrollbar {
  display: none;
}

.servicesTitle {
  color: #ff0b53;
  text-align: center;
  /* margin-top: 55px; */
  height: calc(5% - 5px);
  font-size: 24px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  z-index: 1;
}

.services {
  padding: 10rem;
  margin-top: -45px;
  /* display: flex;
  justify-content: space-between; */
}
.keywords {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-size: calc(15px + (64 - 15) * ((100vw - 769px) / (1920 - 769)));
  /* font-size: 3.5rem; */
  color: #052bf0;
  /* line-height: 78px; */
  margin-top: 40px;
  /* width: calc(90% - 15px); */
  /* margin-left: 1rem;
  margin-right: 1rem; */
  font-weight: 550;
  text-align: center;
}

.buttonParent {
  height: 10rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: calc(-3% - 5px);
}

.hireMarketier {
  width: calc(18% - 5px);
  /* margin-left: calc(1% - 15px); */
  height: 65px;
  /* height: calc(5% - 5px); */
  background-color: #052bf0;
  color: white;
  /* font-size: 24px; */
  font-size: calc(15px + (24 - 15) * ((100vw - 769px) / (1920 - 769)));
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.hireMarketier:hover {
  background-color: #ff0b53;
}

@media screen and (max-width: 768px) {
  .servicesTitle {
    margin-top: -1rem;
    font-size: 20px;
  }
  .services {
    padding: 5rem;
  }
  .keywords {
    font-size: 32px;
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
  .buttonParent {
    margin-top: -50px;
    width: 100%;
  }

  .hireMarketier {
    width: 280px;
    height: 48px;
    background-color: #052bf0;
    color: white;
    font-size: 20px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    border: none;
  }
}

@media screen and (max-width: 426px) {
  .services {
    padding: 3rem;
  }
  .keywords {
    font-size: 22px;
    margin-top: 5rem;
    width: 100%;
  }

  .hireMarketier {
    width: 280px;
    height: 48px;
    background-color: #052bf0;
    color: white;
    font-size: 20px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
  }

  .buttonParent {
    margin-top: 0;
  }

  .servicesTitle {
    margin-top: 3rem;
    font-size: 18px;
  }
}
@media screen and (max-width: 376px) {
  .backgroundServices {
    margin-top: 0px;
  }
  .servicesTitle {
    font-size: 16px;
    margin-top: 70px;
    font-size: 12px;
    width: 100%;
  }

  .services {
    padding: 2rem;
  }

  .keywords {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-size: 22px;
    color: #052bf0;
    /* line-height: 27px; */
    margin-top: 30px;
  }

  .hireMarketier {
    width: 280px;
    height: 48px;
    background-color: #052bf0;
    color: white;
    font-size: 20px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    border: none;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1699px) {
  .buttonParent {
    height: 10rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: calc(-2% - 5px);
  }
  .hireMarketier {
    height: 60px;
  }
}
@media only screen and (min-width: 1501px) and (max-width: 1599px) {
  .buttonParent {
    height: 10rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: calc(-5% - 5px);
  }
  .hireMarketier {
    height: 55px;
  }
}
@media only screen and (min-width: 1431px) and (max-width: 1500px) {
  .buttonParent {
    height: 10rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: calc(-5% - 5px);
  }
  .hireMarketier {
    height: 45px;
  }
}
@media only screen and (min-width: 1365px) and (max-width: 1430px) {
  .buttonParent {
    height: 10rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: calc(-4% - 5px);
  }
  .hireMarketier {
    height: 45px;
  }
}
@media only screen and (min-width: 1267px) and (max-width: 1364px) {
  .buttonParent {
    height: 10rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: calc(-6% - 5px);
  }
  .hireMarketier {
    height: 45px;
  }
}
@media only screen and (min-width: 1060px) and (max-width: 1266px) {
  .buttonParent {
    height: 10rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: calc(-7% - 5px);
  }
  .hireMarketier {
    height: 40px;
  }
}
@media only screen and (max-width: 769px) {
}
@media only screen and (max-width: 426px) {
}
@media only screen and (max-width: 376px) {
}

.App {
  text-align: center;
}

.gradientBackground h1 {
  color: #020d44;
  font-weight: 950;
  /* font-size: 55px; */
  font-size: calc(30px + (74 - 30) * ((100vw - 769px) / (1920 - 769)));
  text-align: left;
  width: 70vw;
  padding: 2.5rem 7rem 0rem;
  margin: 0;
}

.num-bottom {
  position: absolute;
  bottom: 0;
  left: 20%;
  width: 50%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  pointer-events: none;
}

.numzero {
  position: fixed;
  bottom: 40px;
  left: 30%;
  height: 200px;
  width: 180px;
  z-index: 9999;
  margin-right: 20px;
  pointer-events: none;
}

.number {
  position: absolute;
  bottom: 40px;
  left: 39%;
  height: 200px;
  width: 160px;
  pointer-events: none;
  z-index: 9999;
}

.num1 {
  height: 200px;
  pointer-events: none;
  width: 100px;
}

.stepsText {
  color: #020d44;
  text-align: left;
  font-size: 37px;
  font-size: calc(15px + (44 - 15) * ((100vw - 769px) / (1920 - 769)));
  padding: 4rem 7rem;
  width: calc(55% - 15px);
}

.none {
  display: none;
}

.gradientBackground .buttonHireMarketier {
  position: absolute;
  /* left: 6%;
  bottom: 41%;  */
  margin: 1rem 7rem;
  width: calc(18% - 5px);
  /* margin-left: calc(1% - 15px); */
  /* height: 65px; */
  height: calc(8% - 5px);
  background-color: #052bf0;
  color: white;
  /* font-size: 24px; */
  font-size: calc(15px + (24 - 15) * ((100vw - 769px) / (1920 - 769)));
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.gradientBackground .buttonHireMarketier:hover {
  background-color: #ff0b53;
}

.stepnum {
  color: white;
  font-weight: 900;
}

.fixedtext {
  top: 10%;
  left: 8vw;
  font-size: calc(21px + (21 - 21) * ((100vw - 769px) / (1920 - 769)));
  color: #ff0b53;
}

.slide-background {
  box-shadow: 20px 25px 30px rgba(0, 0, 0, 0.25);
}

.gradientBackground {
  background-image: linear-gradient(
    87deg,
    white 40%,
    lightgray 55%,
    transparent 70%
  );
  /* background: rgba(255, 255, 255, 0.7); */
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}

.bsDAVG {
  position: absolute;
  bottom: 1% !important;
  left: 30% !important;
}
.bsDAVG {
  position: absolute;
  bottom: 10px;
  left: 29%;
}

.imgslide1 {
  background: url(/static/media/cekor-1.d6aa5f61.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  position: relative;
}

.imgslide2 {
  background: url(/static/media/cekor-2.c297595f.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  position: relative;
}

.imgslide3 {
  background: url(/static/media/cekor-3.5780df58.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  position: relative;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .step-num {
    margin-left: -210px;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    margin-bottom: -50px;
  }

  .zeroFixed {
    position: fixed;
    bottom: 10px;
    font-weight: 900;
    left: 35%;
    z-index: 9999999;
  }
  /* .gradientBackground .buttonHireMarketier {
    left: 7%;
    bottom: 40%;
  } */
  .gradientBackground {
    background-image: linear-gradient(
      87deg,
      white 40%,
      lightgray 50%,
      transparent 70%
    );
    height: 100vh;
  }

  .gradientBackground h1 {
    width: 90%;
  }

  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 40px;
    left: 30%;
    height: 200px;
    width: 180px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 40px;
    left: 42%;
    height: 200px;
    width: 160px;
    z-index: 9999;
  }

  .num1 {
    height: 200px;
    width: 100px;
  }
}

@media only screen and (min-width: 1501px) and (max-width: 1599px) {
  .step-num {
    margin-left: -210px;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    bottom: 0;
  }
  /* .gradientBackground .buttonHireMarketier {
    left: 7%;
    bottom: 35%;
  } */

  .gradientBackground {
    background-image: linear-gradient(
      87deg,
      white 40%,
      lightgray 50%,
      transparent 70%
    );
    height: 100vh;
  }
  .gradientBackground h1 {
    width: 90%;
  }

  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 40px;
    left: 30%;
    height: 170px;
    width: 160px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 40px;
    left: 42%;
    height: 170px;
    width: 160px;
    z-index: 9999;
  }

  .num1 {
    height: 170px;
    width: 90px;
  }
}
@media only screen and (min-width: 1431px) and (max-width: 1500px) {
  .step-num {
    margin-left: -230px;
    margin-bottom: -40px;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  /* .gradientBackground .buttonHireMarketier {
    left: 8%;
    bottom: 48%;
  } */

  .gradientBackground {
    background-image: linear-gradient(
      87deg,
      white 40%,
      lightgray 45%,
      transparent 70%
    );
    height: 100vh;
  }
  .gradientBackground h1 {
    width: 90%;
  }

  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 40px;
    left: 30%;
    height: 180px;
    width: 170px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 40px;
    left: 44%;
    height: 180px;
    width: 170px;
    z-index: 9999;
  }

  .num1 {
    height: 170px;
    width: 90px;
  }
}

@media only screen and (min-width: 1365px) and (max-width: 1430px) {
  .step-num {
    margin-left: -230px;
    margin-bottom: -80px;
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  /* .gradientBackground .buttonHireMarketier {
    left: 8.5%;
    bottom: 41%;
  } */

  .gradientBackground {
    background-image: linear-gradient(
      87deg,
      white 32%,
      lightgray 42%,
      transparent 70%
    );
    height: 100vh;
  }

  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 40px;
    left: 30%;
    height: 180px;
    width: 170px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 40px;
    left: 44%;
    height: 180px;
    width: 170px;
    z-index: 9999;
  }

  .num1 {
    height: 170px;
    width: 90px;
  }
  .gradientBackground h1 {
    width: 90%;
  }
}
@media only screen and (min-width: 1267px) and (max-width: 1364px) {
  .step-num {
    margin-left: -250px;
    margin-bottom: -100px;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }

  /* .gradientBackground .buttonHireMarketier {
    left: 9%;
    bottom: 46%;
  } */

  .gradientBackground {
    background-image: linear-gradient(
      87deg,
      white 36%,
      lightgray 45%,
      transparent 70%
    );
    height: 100vh;
  }

  .gradientBackground h1 {
    width: 78%;
  }

  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 40px;
    left: 30%;
    height: 160px;
    width: 150px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 40px;
    left: 44%;
    height: 160px;
    width: 150px;
    z-index: 9999;
  }

  .num1 {
    height: 160px;
    width: 70px;
  }
}
@media only screen and (min-width: 1031px) and (max-width: 1266px) {
  .step-num {
    margin-left: -250px;
    margin-bottom: -100px;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
  .gradientBackground .buttonHireMarketier {
    width: 255px;
  }

  .gradientBackground {
    background-image: linear-gradient(
      87deg,
      white 50%,
      lightgray 55%,
      transparent 70%
    );
    height: 100vh;
  }
  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 40px;
    left: 30%;
    height: 170px;
    width: 160px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 40px;
    left: 44%;
    height: 170px;
    width: 160px;
    z-index: 9999;
  }

  .num1 {
    height: 170px;
    width: 80px;
  }
}

@media (max-width: 1030px) {
  .fixedtext {
    font-size: 0.8rem;
  }
  .gradientBackground h1 {
    font-size: 37px;
    width: 70vw;
    padding: 2.5rem 3rem 0rem;
  }
  .gradientBackground .buttonHireMarketier {
    margin: 2.5rem 3rem 0rem;
    width: 255px;
  }
  .stepsText {
    font-size: 25px;
    padding: 1rem 3rem;
    width: 70vw;
  }
  .fixedNum {
    margin-left: -12rem;
  }
  /* .stepnum {
    font-size: 11rem;
  } */
  .marketar {
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 770px) {
  .gradientBackground {
    background-image: linear-gradient(
      180deg,
      white 14%,
      lightgray 30%,
      transparent 70%
    );
    height: 100vh;
  }

  .imgslide1,
  .imgslide2,
  .imgslide3 {
    background-position: bottom right;
  }

  .gradientBackground .buttonHireMarketier {
    /* top: 32%;
    left: 6.5%; */
    width: 255px;
    font-size: 18px;
    font-weight: 700;
  }

  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 20px;
    left: 6%;
    height: 140px;
    width: 130px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 20px;
    left: 8%;
    height: 140px;
    width: 130px;
    z-index: 9999;
  }

  .num1 {
    height: 140px;
    width: 65px;
  }
  .gradientBackground h1 {
    width: 100%;
  }
}

@media (max-width: 426px) {
  .fixedtext {
    font-size: 0.8rem;
  }
  .gradientBackground h1 {
    font-size: 29px;
    width: 100vw;
    padding: 3.5rem 2rem 0rem;
  }

  .gradientBackground .buttonHireMarketier {
    margin: 3.5rem 2rem 0rem;
  }
  .stepsText {
    font-size: 25px;
    padding: 0rem 2rem;
    width: 100vw;
    margin-top: 30px;
  }
  .fixedNum {
    margin-left: -10rem;
  }
  .stepnum {
    font-size: 9rem;
    margin-left: -37px;
  }
  .marketar {
    margin: 3rem;
  }

  .bsDAVG {
    left: 65% !important;
  }
  .imgslide1,
  .imgslide2,
  .imgslide3 {
    background-position: bottom;
  }

  .gradientBackground {
    background-image: linear-gradient(
      180deg,
      white 40%,
      lightgray 48%,
      transparent 71%
    );
    height: 89vh;
    /* margin-top: -70px; */
  }

  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 20px;
    left: 6%;
    height: 100px;
    width: 90px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 20px;
    left: 14%;
    height: 100px;
    width: 90px;
    z-index: 9999;
  }

  .num1 {
    height: 100px;
    width: 45px;
  }
}

@media (max-width: 767px) {
  .bsDAVG {
    left: 60%;
  }
  .stepnum {
    font-size: 8.5rem;
  }
}
.zeromob {
  font-weight: bold;
}
@media (max-width: 426px) {
  .gradientBackground {
    background: rgba(255, 255, 255, 0);
    background-image: linear-gradient(
      180deg,
      white 45%,
      lightgray 53%,
      transparent 57%
    );
    height: 89;
    /* margin-top: -140px; */
  }

  .gradientBackground h1 {
    font-size: 29px;
    width: 100vw;
    padding: 3.5rem 2rem 0rem;
  }

  .gradientBackground .buttonHireMarketier {
    position: absolute;
    width: 255px;
    height: 45px;
    background-color: #052bf0;
    color: white;
    font-size: 20px;
    font-weight: 700;
    border: none;
    cursor: pointer;
  }
}

@media (max-width: 376px) {
  .stepsText {
    font-size: 18px;
    padding: 1rem 2rem;
    margin-top: 20px;
  }
  .gradientBackground {
    background: rgba(255, 255, 255, 0);
    background-image: linear-gradient(
      180deg,
      white 38%,
      lightgray 45%,
      transparent 57%
    );
    height: 85vh;
    /* margin-top: -140px; */
  }

  .gradientBackground h1 {
    font-size: 30px;
    padding: 3.5rem 2rem 0rem;
  }
  .gradientBackground .buttonHireMarketier {
    /* left: 9%;
    top: 48%; */
    color: white;
    font-size: 18px;
    width: 255px;
    font-weight: 700;
    height: 45px;
    left: 9%;
    margin-left: 0;
    margin-top: 0;
    border: none;
  }

  .buttonApplyAsMarketier {
    width: 260px;
    height: 35px;
    background-color: white;
    font-weight: 700;
    font-size: 17px;
    border: none;
    margin-left: 0px;
    margin-top: 0px;
    left: 6%;
    bottom: 30%;
  }

  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 20px;
    left: 6%;
    height: 100px;
    width: 90px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 20px;
    left: 19%;
    height: 100px;
    width: 90px;
    z-index: 9999;
  }

  .num1 {
    height: 100px;
    width: 45px;
  }
}

.item,
.front,
.back {
  height: 15.125em;
  /* height: calc(100vh / 3.8719); */
  /* width: calc(100em / 4.1557); */
  width: 24.0625em;
  margin-bottom: 32px;
  padding: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.16);
  color: #020d44;
  overflow: hidden;
  margin-right: 32px;
}

.user-name {
  font-weight: bolder;
}
.card-header {
  /* height: 30%; */
  width: 100%;
  margin: 0 auto;
  background-color: white;
  display: -webkit-flex;
  display: flex;
  /* align-items: center; */
  -webkit-justify-content: center;
          justify-content: center;
  vertical-align: middle;
}

.card-desc {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.card-header h3 {
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  margin-left: 10px;
}

.card-header p {
  font-size: 16px;
  font-weight: normal;
  width: 98%;
  max-height: 40px;
  text-align: left;
  margin-left: 10px;
  overflow-y: hidden;
  margin-top: 5px;
}
.card-header img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.allCats {
  width: 100%;
  margin: 0 auto;
  display: inline-block;
}

.allCats p {
  font-size: 14px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  float: left;
  margin: 0;
  margin-right: 5px;
  color: darkgray;
}

.frontBottom {
  height: 12%;
  width: 100%;
  margin: 0 auto;
  font-size: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.frontBottom a {
  color: blue;
  text-decoration: underline;
}

.envelope {
  color: darkblue;
  cursor: pointer;
  font-size: 2em;
}

.back {
  background-color: #052bf0;
  color: white;
  position: relative;
}

.back h3 {
  position: absolute;
  font-weight: 600;
  letter-spacing: 1px;
  left: 10%;
  top: 20%;
  font-size: 24px;
}

.back p {
  margin: 0;
  padding: 0;
}

.street {
  position: absolute;
  top: 34%;
  left: 10%;
  font-weight: 100;
}

.ph {
  position: absolute;
  top: 60%;
  left: 10%;
  font-weight: 100;
}

.contactemail {
  position: absolute;
  top: 70%;
  left: 10%;
  font-weight: 100;
  color: white;
}

.contactemail a {
  color: white;
}
.closecard {
  position: absolute;
  cursor: pointer;
  font-size: 1.8em;
  right: 27px;
  bottom: 15px;
}

@media only screen and (min-width: 1741px) and (max-width: 1850px) {
  .item,
  .item .back {
    height: 14em;
    width: 23em;
    margin-bottom: 25px;
    padding: 15px;
  }
  .card-header h3 {
    font-size: 23px;
  }

  .card-desc {
    margin-left: 0;
  }

  .allCats p {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1651px) and (max-width: 1740px) {
  .item,
  .item .back {
    height: 13em;
    width: 22em;
    margin-bottom: 25px;
    padding: 15px;
    margin-right: 25px;
  }

  .card-header {
    width: 100%;
  }

  .card-desc {
    margin-left: 0;
  }

  .card-header h3 {
    font-size: 23px;
  }

  .card-header h3 span {
    font-size: 16px;
  }

  .allCats {
    width: 100%;
  }

  .allCats p {
    font-size: 13px;
    margin-right: 8px;
  }

  .frontBottom {
    width: 100%;
  }

  .closecard {
    right: 19px;
    bottom: 12px;
  }
}

@media only screen and (min-width: 1528px) and (max-width: 1650px) {
  .item,
  .item .back {
    height: 13em;
    width: 20em;
    margin-bottom: 25px;
    padding: 15px;
    margin-right: 25px;
  }

  .card-header {
    width: 100%;
  }

  .card-desc {
    margin-left: 0;
  }

  .card-header h3 {
    font-size: 20px;
  }

  .card-header p {
    font-size: 15px;
  }

  .card-header h3 span {
    font-size: 16px;
  }

  .frontBottom {
    width: 100%;
  }

  .allCats {
    width: 100%;
  }

  .allCats p {
    font-size: 13px;
    margin-right: 5px;
  }

  .back h3 {
    font-size: 20px;
  }

  .closecard {
    right: 17px;
    bottom: 14px;
  }
}

@media only screen and (min-width: 1431px) and (max-width: 1527px) {
  .item,
  .item .back {
    height: 12em;
    width: 19em;
    margin-bottom: 25px;
    padding: 15px;
    margin-right: 22px;
  }

  .card-desc {
    margin-left: 0;
  }

  .card-header h3 {
    font-size: 18px;
  }

  .card-header p {
    font-size: 15px;
  }

  .card-header {
    width: 100%;
  }
  .allCats {
    width: 100%;
  }

  .allCats p {
    font-size: 12px;
    margin-right: 6px;
  }

  .frontBottom {
    width: 100%;
  }

  .closecard {
    right: 16px;
    bottom: 10px;
  }

  .back h3 {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1371px) and (max-width: 1430px) {
  .item,
  .item .back {
    height: 12em;
    width: 18.2em;
    margin-bottom: 25px;
    padding: 15px;
    margin-right: 28px;
  }

  .card-header {
    width: 100%;
  }

  .card-desc {
    margin-left: 0;
  }

  .card-header h3 {
    font-size: 19px;
    white-space: nowrap;
  }

  .card-header h3 span {
    font-size: 15px;
  }

  .allCats {
    width: 100%;
  }

  .allCats p {
    font-size: 12px;
    margin-right: 5px;
  }

  .frontBottom {
    width: 100%;
  }

  .back h3 {
    font-size: 20px;
  }

  .closecard {
    right: 16px;
    bottom: 10px;
  }
}

@media only screen and (min-width: 1267px) and (max-width: 1370px) {
  .item,
  .item .back {
    height: 11em;
    width: 17.5em;
    margin-bottom: 25px;
    padding: 15px;
    margin-right: 20px;
  }

  .card-header {
    width: 100%;
  }

  .card-desc {
    margin-left: 0;
  }

  .card-header h3 {
    font-size: 19px;
    white-space: nowrap;
  }

  .card-header h3 span {
    font-size: 12px;
  }

  .allCats {
    width: 100%;
  }

  .allCats p {
    font-size: 10px;
    margin-right: 8px;
  }

  .frontBottom {
    width: 100%;
  }

  .back h3 {
    font-size: 19px;
  }

  .card-header img {
    width: 40px;
    height: 40px;
  }

  .street {
    font-size: 14px;
  }

  .ph,
  .contactemail {
    font-size: 14px;
  }

  .closecard {
    right: 17px;
    bottom: 10px;
  }
}

@media only screen and (min-width: 1060px) and (max-width: 1266px) {
  .item,
  .front,
  .back {
    width: 20.5em;
    height: 12.5em;
    margin-right: 32px;
    padding: 15px;
  }

  .card-header {
    width: 100%;
  }
  .card-header h3 {
    font-size: 21px;
  }

  .card-header h3 span {
    font-size: 14px;
  }

  .allCats {
    margin-top: 25px;
    width: 100%;
    height: 30%;
  }

  .frontBottom {
    width: 100%;
  }

  .allCats p {
    font-size: 12px;
  }

  .back h3 {
    font-size: 21px;
  }

  .street {
    font-size: 15px;
  }

  .ph,
  .contactemail {
    font-size: 15px;
  }

  .closecard {
    right: 15px;
    bottom: 10px;
  }
}

@media only screen and (max-width: 769px) {
  .item,
  .front,
  .back {
    width: 22em;
    height: 14em;
    margin-left: 18px;
    margin-right: 10px;
  }

  .card-header h3 {
    font-size: 20px;
  }

  .card-header p {
    font-size: 15px;
  }

  .allCats {
    margin-top: 10px;
  }

  .allCats p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 426px) {
  .item,
  .front,
  .back {
    margin-right: 0;
    margin-left: 0;
  }

  .card-header {
    width: 100%;
  }

  .card-header h3 {
    font-size: 21px;
  }

  .back h3 {
    font-size: 21px;
  }

  .card-desc {
    margin-left: 0;
  }

  .allCats,
  .frontBottom {
    width: 100%;
  }

  .closecard {
    right: 17px;
  }
}

@media only screen and (max-width: 376px) {
  .item,
  .front,
  .back {
    height: 14em;
    width: 21em;
    padding: 20px;
  }

  .card-header {
    width: 100%;
  }

  .card-header h3 {
    font-size: 20px;
  }

  .allCats {
    width: 100%;
    height: 35%;
  }

  .allCats p {
    font-size: 11px;
  }

  .frontBottom {
    width: 100%;
  }

  .back h3 {
    font-size: 20px;
  }

  .street,
  .ph,
  .contactemail {
    font-size: 15px;
  }

  .closecard {
    right: 21px;
    bottom: 17px;
  }
}

@media only screen and (height: 900px) and (width: 1600px) {
  .item,
  .front,
  .back {
    height: 14em;
    width: 21em;
    padding: 20px;
  }

  .card-desc {
    margin-left: 0;
  }

  .card-header h3 {
    font-size: 21px;
  }
}

.filterButton {
  font-size: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  margin-bottom: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.filterButton button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
}

.filterButton:hover {
  font-weight: bold;
}

.filterButton:focus,
.filterButton button:focus {
  outline: none;
}
.box-sm {
  min-height: 15px;
  min-width: 15px;
  border: 1px solid gray;
  margin-right: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.checkmark {
  font-size: 13px;
  color: blue;
}

.graycheck {
  font-size: 13px;
  color: lightgray;
}

@media only screen and (max-width: 1805px) {
  .filterButton button {
    font-size: 14px;
  }

  .filterButton {
    font-size: 14px;
  }
}

/* @media only screen and (max-width: 1600px) {
  .filterButton {
    font-size: 13px;
  }
} */

@media only screen and (min-width: 1600px) and (max-width: 1699px) {
}

@media only screen and (min-width: 1501px) and (max-width: 1599px) {
  .filterButton button {
    font-size: 16px;
  }
  .filterButton {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1431px) and (max-width: 1500px) {
  .filterButton {
    font-size: 15px;
  }
  .filterButton button {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1365px) and (max-width: 1430px) {
  .filterButton {
    font-size: 14px;
  }
  .filterButton button {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1267px) and (max-width: 1364px) {
  .filterButton {
    font-size: 13px;
    margin-bottom: 20px;
  }
  .filterButton button {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1060px) and (max-width: 1266px) {
  .filterButton {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .filterButton button {
    font-size: 14px;
  }
}

@media only screen and (max-width: 769px) {
  .filterButton {
    font-size: 18px;
    margin-bottom: 20px;
    margin-left: 2px;
  }
  .filterButton button {
    font-size: 18px;
  }

  .checkmark {
    font-size: 12px;
  }
}

@media only screen and (max-width: 426px) {
  .box-sm {
    min-height: 22px;
    min-width: 22px;
  }

  .checkmark {
    font-size: 14px;
  }

  .filterButton {
    font-size: 16px;
    margin-bottom: 7px;
    margin-left: 0px;
  }
  .filterButton button {
    font-size: 16px;
  }
}

@media only screen and (max-width: 376px) {
  .filterButton {
    font-size: 15px;
    margin-bottom: 7px;
  }
  .filterButton button {
    font-size: 15px;
  }
}

.map-container {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 40px;
  background-color: transparent;
}

.map-content {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 2px solid lightgray;
  padding-bottom: 130px;
  min-height: 100vh;
  position: relative;
}
.filters {
  width: 20%;
  height: 1000px;
  position: relative;
}

.fixed-filter {
  position: fixed;
  top: 10px;
}

.filter-header {
  width: 95%;
  margin-left: 10px;
  display: -webkit-flex;
  display: flex;
  padding-left: 0px;
  padding-bottom: 30px;
  border-bottom: 1px solid lightgray;
}
.btnBB {
  margin-left: 0px;
  margin-right: 50px;
  text-align: left;
  font-size: 18px;
  line-height: 1.24;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 0;
}
.map-header {
  height: 72vh;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.map-header h1 {
  width: 100%;
  padding: 0;
  font-size: 66px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  color: #020d44;
}

.map-header p {
  margin-top: 25px;
  text-align: left;
  font-size: 28px;
  font-weight: normal;
  color: #020d44;
  line-height: 1.34;
}

.filters h3 {
  padding-left: 10px;
  margin-bottom: 37px;
  text-align: left;
  color: #052bf0;
  font-weight: bolder;
  font-size: 24px;
}

.filter-buttons .filterButton {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.filter-buttons {
  margin-top: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-left: 10px;
  font-size: 18px;
}

.map-cards {
  width: 76%;
  background-color: transparent;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  min-height: 500px;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  position: relative;
}

.move-cards-left {
  margin-left: 24%;
}

.imgonfilter {
  position: absolute;
  right: -100px;
  top: -450px;
  z-index: 999;
}

.showmore {
  position: absolute;
  color: blue;
  cursor: pointer;
  left: 41%;
  bottom: -60px;
  font-size: 24px;
  font-weight: 600;
  text-decoration: underline;
}

.hideshow {
  display: none;
}

.main-wrap {
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
}

.main-wrap .picpic {
  position: absolute;
  right: 0;
  top: 0;
}

.ctacard h1 {
  font-size: 74px;
  font-weight: bolder;
  padding: 0;
  margin: 30px 0 30px 0;
}

.ctacard {
  width: 90%;
  margin: 0 auto;
  text-align: left;
  position: relative;
  padding-bottom: 130px;
  color: #020d44;
  padding-top: 60px;
}

.ctacard p {
  font-size: 24px;
  font-weight: 500;
  color: #020d44;
}

.ctacard button {
  position: absolute;
  top: 50%;
  right: 10%;
  font-size: 24px;
  color: white;
  padding: 20px;
  background-color: #052bf0;
  border: none;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  font-weight: 700;
}

.ctacard button:hover {
  background-color: #ff0b53;
}

.map-circle {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIEAAACBCAMAAADQfiliAAAAAXNSR0IB2cksfwAAAHtQTFRFAAAA/wtT/wBN/wpT/wpR/QxR/wtO/whT/wtT/ghV/wpR/wlT/wpT/whR/wpT/wpR/wtP/wpT/wtT/whO/wlR/wtU/wxG/wxT/wdO/wtR/wVU/glS/wtS/QtR/wBR/wtQ9whN/wtR/wpS/AZP/wpU/wdV/wtV/wtT/wpT6YIAqQAAACl0Uk5TAP8I4p6EMH7UJKGt9GOyZ17PeD6IuhbFRe43lL6kCWAhjf4t5ScwjvdjKc6/AAACyklEQVR4nO2c616yQBCHd0AUVMTzKTO1t3rv/wpjISMMlD3+50PPBTTPT3ZnTzMJocN6kUbD1SzckWQXzlbDKF2stf6WMsF2MAmpmXAy2AZuw/fnh5bgFYd531n4KH4YviSOHEgE2aZj+JJNZvdzJOexUnzJ+JxYi//aUw5f0nu1Ej/RjV84mP8OwdIgvmRpOB6mbVO/O+HUIP5pZBxfMjrpCqTqE6CZcaoVPzAZgbf0NEbDvmsC7Ea8VxWYXqwKEF0UB+TAcnzJQEXgyYEA0VN3gWcnAkTPXQXsZIEmRmiBjgquPkFJhw/hZhBWPByOLqZhnQeT8sW5ANHd1LT3IECXOwn6aHctaCNuX6aGXgTylbJNIPMkQNSyX0j+ezMYnxoNJt4EWnKjj4lY0TAlj/+8GoS/54PpuUCV5a3Am2cBotvTlM2NcTduksK7dwGi+rHWVzb8Se1HSAAC9ZHwATE4VwKBrQOiGuMqJ/hbkupk3wZql1T22FwF+iABouulXwQziL4M/OzNmojRH+H6GeZAg3lh8Piy2h2HIh0BBYhkUtpCDbbCx0nxHvIU6XOL/JtJbmB+b2tCKMQaKkC0FguwwUKkYIMUuCyVRJA96k+GYgU2WIkZ2GAGTgcyIezABjsBFiD6M/gzKAzwcwGfD/A5Eb8u4NdG/P4Av0fC7xPxe2X8eYHBmQl/bsSfnfH3B/g7FAb3SPi7NPx9IoM7Vfy9Mv5uncH7Av6NBf/OxOCtDf/eyODNFf/uzODtHV9/wKAGA1+HwqAWh0E9Er4mi0FdGoPaPHx9IoMaTQZ1qgxqdRnUKzOo2WZQt86gdp9B/wKDHg4HqUm1j4VBL0++TNlcKXX6mXIyW1sWzZ6unMTOxk2/ry3nxXwHbdTbl3NE9zfmvIF7PCXvurPCUp+rJPnA9vpK0P3OBeCe7y8JbN97Cbj3/4rN/3/wCUO+SSE+3v0hAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  height: 200px;
  width: 200px;
  position: absolute;
  left: -110px;
  top: 50%;
}

/* @media only screen and (min-width: 1475px) and (max-width: 1674px) {
  .btnBB {
    font-size: 14px;
    margin-right: 25px;
  }

  .filter-header {
    margin-left: 0;
    width: 100%;
  }
} */
@media only screen and (min-width: 1600px) and (max-width: 1699px) {
  .map-container {
    width: 92%;
  }

  .filters {
    width: 24%;
  }

  .filter-header {
    width: 95%;
  }

  .map-cards {
    width: 76%;
  }
}

@media only screen and (min-width: 1501px) and (max-width: 1599px) {
  .map-container {
    width: 93%;
  }

  .filters {
    width: 25%;
  }

  .filter-header {
    width: 94%;
  }

  .map-cards {
    width: 75%;
  }

  .btnBB {
    font-size: 16px;
    margin-left: 10px;
  }

  .ctacard button {
    top: 60%;
  }
}

@media only screen and (min-width: 1431px) and (max-width: 1500px) {
  .map-container {
    width: 94%;
  }

  .filters {
    width: 25%;
  }

  .filter-header {
    width: 90%;
  }

  .btnBB {
    font-size: 15px;
    margin-left: 8px;
  }

  .map-cards {
    width: 77%;
  }

  .main-wrap .picpic {
    height: 500px;
    width: 400px;
    top: 100px;
  }
  .ctacard button {
    top: 65%;
  }
}

@media only screen and (min-width: 1365px) and (max-width: 1430px) {
  .filters {
    width: 24%;
  }

  .map-cards {
    width: 76%;
  }

  .filter-header {
    width: 95%;
  }

  .btnBB {
    font-size: 14px;
    margin-left: 5px;
  }

  .map-container {
    width: 93%;
  }

  .main-wrap .picpic {
    height: 500px;
    width: 400px;
    top: 100px;
  }

  .ctacard button {
    top: 65%;
  }
}

@media only screen and (min-width: 1267px) and (max-width: 1364px) {
  .map-container {
    width: 95%;
  }

  .filters {
    width: 24%;
  }

  .map-cards {
    width: 76%;
  }

  .filter-header {
    width: 90%;
  }

  .btnBB {
    margin-left: 0px;
    font-size: 13px;
  }

  .main-wrap .picpic {
    height: 450px;
    width: 350px;
    top: 100px;
  }

  .map-header h1 {
    font-size: 60px;
  }

  .map-header p {
    font-size: 26px;
  }
  .ctacard button {
    top: 65%;
  }
}

@media only screen and (min-width: 1060px) and (max-width: 1266px) {
  .map-container {
    width: 97%;
  }

  .map-content {
    width: 100%;
  }
  .filters {
    width: 29%;
  }

  .map-cards {
    width: 80%;
    margin-left: 35px;
  }

  .filter-header {
    width: 90%;
  }

  .btnBB {
    font-size: 14px;
    margin-left: 2px;
  }

  .filter-buttons {
    margin-left: 8px;
  }

  .main-wrap .picpic {
    height: 450px;
    width: 350px;
    top: 100px;
  }

  .map-header h1 {
    font-size: 60px;
  }

  .map-header p {
    font-size: 26px;
  }

  .ctacard button {
    top: 85%;
    left: 35%;
  }
}

@media only screen and (max-width: 769px) {
  .showmore {
    left: 40%;
  }
  .main-wrap {
    width: 100%;
  }

  .map-container {
    width: 100%;
  }
  .map-content {
    width: 100%;
  }

  .map-header {
    width: 100%;
    padding: 20px;
    height: 62vh;
  }

  .map-cards {
    margin-left: 0px;
    width: auto;
    margin: 0 auto;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }

  .main-wrap .picpic {
    height: 375px;
    width: 285px;
    top: 0px;
    z-index: -1;
  }

  .map-header h1 {
    font-size: 62px;
  }

  .map-header p {
    font-size: 24px;
  }

  .ctacard h1 {
    font-size: 50px;
    white-space: nowrap;
    margin: 0px 0 30px 0;
  }

  .ctacard {
    padding-top: 20px;
  }

  .ctacard p {
    font-size: 22px;
    font-weight: 500;
    color: #020d44;
  }

  .ctacard button {
    position: absolute;
    top: 80%;
    left: 60%;
    font-size: 20px;
    color: white;
    padding: 18px;
    margin-top: 10px;
    background-color: #052bf0;
    border: none;
    -webkit-transform: translate(-80%, -60%);
            transform: translate(-80%, -60%);
    cursor: pointer;
  }

  .btnBB {
    font-size: 18px;
    width: 38%;
    padding: 0;
    margin: 0;
    margin-right: 25px;
  }

  .filter-header {
    width: 88%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .filter-cont {
    display: none;
    height: 0;
  }

  .filters {
    width: 0;
    height: 0;
    position: relative;
  }

  .filterBTNup,
  .filterBTNdown {
    position: fixed;
    bottom: 0;
    z-index: 99999;

    padding: 0;
    background-color: #052bf0;
    color: white;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 18px;
    padding: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    height: 55px;
  }

  .btnDOWN,
  .btnUP {
    position: fixed;
    z-index: 99999;
    bottom: 0;
    padding: 0;
    background-color: #052bf0;
    color: white;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 18px;
    padding: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    height: 55px;
    -webkit-animation: slidedown 0.5s;
            animation: slidedown 0.5s;
  }

  @-webkit-keyframes slidedown {
    from {
      bottom: 100%;
    }
    to {
      bottom: 0;
    }
  }

  @keyframes slidedown {
    from {
      bottom: 100%;
    }
    to {
      bottom: 0;
    }
  }

  .btnUP {
    top: 0;
    -webkit-animation: slideup 0.5s;
            animation: slideup 0.5s;
  }
  @-webkit-keyframes slideup {
    from {
      bottom: 0;
      top: 100%;
    }
    to {
      bottom: 100%;
      top: 0;
    }
  }
  @keyframes slideup {
    from {
      bottom: 0;
      top: 100%;
    }
    to {
      bottom: 100%;
      top: 0;
    }
  }

  .arrowup,
  .arrowdown {
    color: white;
  }

  .toggledFilter,
  .closedFilter {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    -webkit-animation: toggleFilter 0.5s;
            animation: toggleFilter 0.5s;
    z-index: 9999;
    background-color: white;
    padding-top: 55px;
  }

  .closedFilter {
    height: 0;
    -webkit-animation: closeFilter 0.5s;
            animation: closeFilter 0.5s;
    padding-top: 55px;
  }
  @-webkit-keyframes toggleFilter {
    from {
      height: 0;
    }
    to {
      height: 100%;
    }
  }
  @keyframes toggleFilter {
    from {
      height: 0;
    }
    to {
      height: 100%;
    }
  }

  @-webkit-keyframes closeFilter {
    from {
      height: 100%;
    }
    to {
      height: 0;
    }
  }

  @keyframes closeFilter {
    from {
      height: 100%;
    }
    to {
      height: 0;
    }
  }
}
@media only screen and (max-width: 426px) {
  .map-header h1 {
    font-size: 35px;
    width: 100%;
    margin-top: 145px;
    z-index: 0;
    text-align: left;
  }

  .map-header p {
    width: 90%;
    font-size: 18px;
  }

  .main-wrap {
    width: 100%;
  }

  .main-wrap .picpic {
    height: 280px;
    width: 200px;
    object-fit: cover;
    right: 0;
    top: 0;
  }

  .map-container {
    width: 100%;
  }

  .showmore {
    margin-left: -60px;
  }

  .map-header {
    width: 90%;
    margin: 0 auto;
    height: 55vh;
  }

  .map-content {
    width: 100%;
    -webkit-justify-content: unset;
            justify-content: unset;
  }

  .ctacard button {
    top: 85%;
    left: 13%;
    -webkit-transform: translateY(-13%);
            transform: translateY(-13%);
  }

  .ctacard h1 {
    white-space: normal;
  }

  .filter-cont {
    display: none;
    height: 0;
  }

  .filters {
    width: 0;
    height: 0;
    position: relative;
  }

  .filterBTNup,
  .filterBTNdown {
    position: fixed;
    bottom: 0;
    padding: 0;
    background-color: #052bf0;
    color: white;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 18px;
    padding: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    z-index: 99999;
    height: 55x;
  }

  .btnDOWN,
  .btnUP {
    z-index: 99999;
    position: fixed;
    bottom: 0;
    padding: 0;
    background-color: #052bf0;
    color: white;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 20px;
    padding: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    height: 55px;
    -webkit-animation: slidedown 0.5s;
            animation: slidedown 0.5s;
  }

  @-webkit-keyframes slidedown {
    from {
      bottom: 100%;
    }
    to {
      bottom: 0;
    }
  }

  @keyframes slidedown {
    from {
      bottom: 100%;
    }
    to {
      bottom: 0;
    }
  }

  .btnUP {
    top: 0;
    -webkit-animation: slideup 0.5s;
            animation: slideup 0.5s;
  }
  @-webkit-keyframes slideup {
    from {
      bottom: 0;
      top: 100%;
    }
    to {
      bottom: 100%;
      top: 0;
    }
  }
  @keyframes slideup {
    from {
      bottom: 0;
      top: 100%;
    }
    to {
      bottom: 100%;
      top: 0;
    }
  }

  .arrowup,
  .arrowdown {
    color: white;
  }

  .toggledFilter,
  .closedFilter {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    -webkit-animation: toggleFilter 0.5s;
            animation: toggleFilter 0.5s;
    background-color: white;
    border: none;
  }

  .closedFilter {
    height: 0;
    -webkit-animation: closeFilter 0.5s;
            animation: closeFilter 0.5s;
    padding-top: 55px;
  }
  @-webkit-keyframes toggleFilter {
    from {
      height: 0;
    }
    to {
      height: 100%;
    }
  }
  @keyframes toggleFilter {
    from {
      height: 0;
    }
    to {
      height: 100%;
    }
  }

  @-webkit-keyframes closeFilter {
    from {
      height: 100%;
    }
    to {
      height: 0;
    }
  }

  @keyframes closeFilter {
    from {
      height: 100%;
    }
    to {
      height: 0;
    }
  }

  .filter-header {
    width: 85%;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .btnBB {
    font-size: 17px;
    margin-bottom: 0;
  }

  .filter-buttons {
    width: 82%;
    margin: 20px auto;
  }

  .map-cards {
    width: 90%;
    margin: 0 auto;
    padding: 0;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .showmore {
    margin-left: 0;
    left: 33%;
  }

  .ctacard h1 {
    font-size: 36px;
  }

  .ctacard p {
    font-size: 20px;
  }

  .ctacard button {
    top: 75%;
    left: 12%;
    width: 280px;
    height: 48px;
    padding: 0;
  }

  .showmore {
    left: 28%;
  }
}

@media only screen and (max-width: 376px) {
  .main-wrap .picpic {
    height: 180px;
    width: 160px;
    object-fit: cover;
    right: 0;
    top: 0;
  }

  .map-header h1 {
    margin-top: 75px;
  }

  .map-header {
    padding: 5px;
    height: 50vh;
  }

  .btnBB {
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .filter-buttons {
    margin-top: 8px;
    width: 83.5%;
  }

  .ctacard button {
    left: 8%;
  }

  .showmore {
    left: 25%;
  }
}

.wrapper-main {
  width: 90%;
  margin: 0 auto;
  background: transparent;
}

.container-map {
  display: -webkit-flex;
  display: flex;
  width: auto;
  width: 100%;
  margin: 0 auto;
  background: #fafafa;
  height: auto;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background: transparent;
  border-bottom: 2px solid lightgray;
}

.main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0 0 77%;
          flex: 0 0 77%;
  height: auto;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  position: relative;
  padding-bottom: 220px;
  margin-left: 5%;
}

.sidebar {
  -webkit-flex: 0 0 20%;
          flex: 0 0 20%;
  background: transparent;
}

.sidebar-filters h3 {
  padding-left: 10px;
  margin-bottom: 37px;
  text-align: left;
  color: #052bf0;
  font-weight: bolder;
  font-size: 24px;
}

.sidebar-filters {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: auto;
  background: transparent;
}
.picpic {
  position: absolute;
  right: 0;
  top: 0;
  height: 660px;
  width: 460px;
}

.showmore {
  position: absolute;
  color: blue;
  cursor: pointer;
  left: 41%;
  bottom: 100px;
  font-size: 24px;
  font-weight: 600;
  text-decoration: underline;
}

.spinnermain {
  height: 50vh;
  width: 100%;
  background-image: url(/static/media/blue-hourglass.91b7e8cf.gif);
  background-attachment: local;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  color: #020d44;
  font-size: 32px;
  font-weight: bolder;
}

.spin {
  background-image: url(/static/media/spin.4873e34e.gif);
  height: 150px;
  width: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: transparent;
}

.err-msg {
  position: absolute;
  left: 15%;
  top: 10%;
  width: 100%;
  color: red;
}

.err-sm {
  top: 2%;
  left: 25%;
}

@media only screen and (min-width: 1600px) and (max-width: 1699px) {
  .sidebar {
    -webkit-flex: 0 0 22%;
            flex: 0 0 22%;
  }
  .filter-header {
    width: 95%;
    margin-left: 10px;
  }

  .container-map {
    width: 100%;
  }

  .main {
    -webkit-flex: 0 0 77%;
            flex: 0 0 77%;
  }

  .picpic {
    top: 50px;
    height: 620px;
    width: 420px;
  }
  .map-circle {
    height: 170px;
    width: 170px;
    position: absolute;
    left: -110px;
    top: 50%;
  }
}

@media only screen and (min-width: 1501px) and (max-width: 1599px) {
  .sidebar {
    -webkit-flex: 0 0 19%;
            flex: 0 0 19%;
  }

  .filter-header {
    width: 94%;
    margin-left: 0;
  }

  .picpic {
    top: 80px;
    height: 660px;
    width: 460px;
  }

  .main {
    -webkit-flex: 0 0 77%;
            flex: 0 0 77%;
    margin-left: 0px;
  }

  .btnBB {
    font-size: 16px;
    margin-left: 10px;
  }

  .ctacard button {
    top: 60%;
  }
  .map-circle {
    height: 170px;
    width: 170px;
    position: absolute;
    left: -110px;
    top: 50%;
  }

  .picpic {
    top: 40px;
    height: 590px;
    width: 390px;
  }
}

@media only screen and (min-width: 1431px) and (max-width: 1500px) {
  .wrapper-main {
    width: 93%;
  }
  .filters {
    width: 20%;
  }

  .filter-header {
    margin-left: 0;
  }
  .main {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    margin-left: 14px;
  }
  .picpic {
    top: 30px;
    height: 590px;
    width: 390px;
  }
  .map-circle {
    height: 150px;
    width: 150px;
    position: absolute;
    left: -110px;
    top: 50%;
  }
}

@media only screen and (min-width: 1365px) and (max-width: 1430px) {
  .wrapper-main {
    width: 96%;
  }
  .sidebar {
    -webkit-flex: 0 0 18%;
            flex: 0 0 18%;
  }

  .main {
    -webkit-flex: 0 0 74%;
            flex: 0 0 74%;
    margin-left: 2%;
  }
  .filter-header {
    width: 95%;
    margin-left: 5px;
  }

  .btnBB {
    font-size: 14px;
    margin-left: 5px;
  }

  .map-container {
    width: 93%;
  }

  .ctacard button {
    top: 65%;
  }

  .picpic {
    top: 30px;
    height: 600px;
    width: 400px;
  }
  .map-circle {
    height: 130px;
    width: 130px;
    position: absolute;
    left: -110px;
    top: 50%;
  }
}

@media only screen and (min-width: 1267px) and (max-width: 1364px) {
  .wrapper-main {
    width: 96%;
  }

  .filter-header {
    width: 90%;
    margin-left: 10px;
  }

  .sidebar {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
  }
  .main {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    margin-left: 5px;
  }

  .picpic {
    top: 30px;
    height: 550px;
    width: 350px;
  }
  .map-circle {
    display: none;
  }
}

@media only screen and (min-width: 1060px) and (max-width: 1266px) {
  .wrapper-main {
    width: 95%;
  }

  .filter-header {
    width: 96%;
    margin-left: 6px;
  }

  .sidebar {
    -webkit-flex: 0 0 30%;
            flex: 0 0 30%;
  }

  .main {
    -webkit-flex: 0 0 69%;
            flex: 0 0 69%;
    margin-left: 25px;
  }

  .picpic {
    top: 100px;
    height: 600px;
    width: 400px;
  }
}

@media only screen and (max-width: 770px) {
  .wrapper-main {
    width: 100%;
  }

  .main {
    margin-left: 0;
    -webkit-flex: 1 0 100%;
            flex: 1 0 100%;
  }

  .picpic {
    height: 300px;
    width: 200px;
    z-index: -1;
    top: 20px;
  }
}
@media only screen and (max-width: 426px) {
  .main {
    margin-left: 0;
    -webkit-flex: 0 0 93%;
            flex: 0 0 93%;
  }

  .map-header {
    width: 95%;
  }

  .showmore {
    left: 24%;
  }

  .filter-header {
    margin-left: auto;
    margin: 0 auto;
    width: 95%;
  }

  .filter-buttons {
    width: 89.5%;
    margin: 20px auto;
  }

  .filterButton {
    margin-bottom: 15px;
  }

  .btnBB {
    margin-bottom: 0;
  }

  .spinnermain {
    height: 60vh;
  }

  .err-msg {
    font-size: 26px;
    width: 80%;
    top: 15%;
  }

  .err-sm {
    top: 10%;
    left: 10%;
  }
}

@media only screen and (max-width: 420px) {
  .main {
    margin-left: 0;
    -webkit-flex: 0 0 92%;
            flex: 0 0 92%;
  }
  .map-header {
    width: 92%;
  }
}

@media only screen and (max-width: 377px) {
  .picpic {
    height: 220px;
    width: 150px;
    z-index: -1;
    top: 50px;
  }
  .main {
    margin-left: 0;
    -webkit-flex: 0 0 95%;
            flex: 0 0 95%;
  }
  .filter-buttons {
    width: 90%;
  }
  .filterButton {
    font-size: 15px;
    margin-bottom: 9px;
  }
  .map-header {
    width: 90%;
  }
  .err-msg {
    font-size: 24px;
    width: 80%;
    top: 15%;
  }

  .err-sm {
    top: 5%;
    left: 5%;
  }
}
@media only screen and (max-width: 361px) {
  .main {
    -webkit-flex: 0 0 96.5%;
            flex: 0 0 96.5%;
  }

  .map-header {
    width: 94%;
  }
}

@media only screen and (min-height: 871px) and (max-height: 901px) {
  .filterButton {
    font-size: 15px;
    margin-bottom: 22px;
  }

  .filter-header {
    padding-bottom: 5px;
    margin-left: 0;
  }
  .picpic {
    top: 75px;
    height: 560px;
    width: 380px;
  }
}

@media only screen and (min-height: 731px) and (max-height: 380px) {
  .picpic {
    height: 220px;
    width: 150px;
    z-index: -1;
    top: 50px;
  }
}

