@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");

html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: "Montserrat", sans-serif;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

body {
  position: relative;
  background-color: white;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}
