.item,
.front,
.back {
  height: 15.125em;
  /* height: calc(100vh / 3.8719); */
  /* width: calc(100em / 4.1557); */
  width: 24.0625em;
  margin-bottom: 32px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.16);
  color: #020d44;
  overflow: hidden;
  margin-right: 32px;
}

.user-name {
  font-weight: bolder;
}
.card-header {
  /* height: 30%; */
  width: 100%;
  margin: 0 auto;
  background-color: white;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  vertical-align: middle;
}

.card-desc {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.card-header h3 {
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  margin-left: 10px;
}

.card-header p {
  font-size: 16px;
  font-weight: normal;
  width: 98%;
  max-height: 40px;
  text-align: left;
  margin-left: 10px;
  overflow-y: hidden;
  margin-top: 5px;
}
.card-header img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.allCats {
  width: 100%;
  margin: 0 auto;
  display: inline-block;
}

.allCats p {
  font-size: 14px;
  width: fit-content;
  float: left;
  margin: 0;
  margin-right: 5px;
  color: darkgray;
}

.frontBottom {
  height: 12%;
  width: 100%;
  margin: 0 auto;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.frontBottom a {
  color: blue;
  text-decoration: underline;
}

.envelope {
  color: darkblue;
  cursor: pointer;
  font-size: 2em;
}

.back {
  background-color: #052bf0;
  color: white;
  position: relative;
}

.back h3 {
  position: absolute;
  font-weight: 600;
  letter-spacing: 1px;
  left: 10%;
  top: 20%;
  font-size: 24px;
}

.back p {
  margin: 0;
  padding: 0;
}

.street {
  position: absolute;
  top: 34%;
  left: 10%;
  font-weight: 100;
}

.ph {
  position: absolute;
  top: 60%;
  left: 10%;
  font-weight: 100;
}

.contactemail {
  position: absolute;
  top: 70%;
  left: 10%;
  font-weight: 100;
  color: white;
}

.contactemail a {
  color: white;
}
.closecard {
  position: absolute;
  cursor: pointer;
  font-size: 1.8em;
  right: 27px;
  bottom: 15px;
}

@media only screen and (min-width: 1741px) and (max-width: 1850px) {
  .item,
  .item .back {
    height: 14em;
    width: 23em;
    margin-bottom: 25px;
    padding: 15px;
  }
  .card-header h3 {
    font-size: 23px;
  }

  .card-desc {
    margin-left: 0;
  }

  .allCats p {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1651px) and (max-width: 1740px) {
  .item,
  .item .back {
    height: 13em;
    width: 22em;
    margin-bottom: 25px;
    padding: 15px;
    margin-right: 25px;
  }

  .card-header {
    width: 100%;
  }

  .card-desc {
    margin-left: 0;
  }

  .card-header h3 {
    font-size: 23px;
  }

  .card-header h3 span {
    font-size: 16px;
  }

  .allCats {
    width: 100%;
  }

  .allCats p {
    font-size: 13px;
    margin-right: 8px;
  }

  .frontBottom {
    width: 100%;
  }

  .closecard {
    right: 19px;
    bottom: 12px;
  }
}

@media only screen and (min-width: 1528px) and (max-width: 1650px) {
  .item,
  .item .back {
    height: 13em;
    width: 20em;
    margin-bottom: 25px;
    padding: 15px;
    margin-right: 25px;
  }

  .card-header {
    width: 100%;
  }

  .card-desc {
    margin-left: 0;
  }

  .card-header h3 {
    font-size: 20px;
  }

  .card-header p {
    font-size: 15px;
  }

  .card-header h3 span {
    font-size: 16px;
  }

  .frontBottom {
    width: 100%;
  }

  .allCats {
    width: 100%;
  }

  .allCats p {
    font-size: 13px;
    margin-right: 5px;
  }

  .back h3 {
    font-size: 20px;
  }

  .closecard {
    right: 17px;
    bottom: 14px;
  }
}

@media only screen and (min-width: 1431px) and (max-width: 1527px) {
  .item,
  .item .back {
    height: 12em;
    width: 19em;
    margin-bottom: 25px;
    padding: 15px;
    margin-right: 22px;
  }

  .card-desc {
    margin-left: 0;
  }

  .card-header h3 {
    font-size: 18px;
  }

  .card-header p {
    font-size: 15px;
  }

  .card-header {
    width: 100%;
  }
  .allCats {
    width: 100%;
  }

  .allCats p {
    font-size: 12px;
    margin-right: 6px;
  }

  .frontBottom {
    width: 100%;
  }

  .closecard {
    right: 16px;
    bottom: 10px;
  }

  .back h3 {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1371px) and (max-width: 1430px) {
  .item,
  .item .back {
    height: 12em;
    width: 18.2em;
    margin-bottom: 25px;
    padding: 15px;
    margin-right: 28px;
  }

  .card-header {
    width: 100%;
  }

  .card-desc {
    margin-left: 0;
  }

  .card-header h3 {
    font-size: 19px;
    white-space: nowrap;
  }

  .card-header h3 span {
    font-size: 15px;
  }

  .allCats {
    width: 100%;
  }

  .allCats p {
    font-size: 12px;
    margin-right: 5px;
  }

  .frontBottom {
    width: 100%;
  }

  .back h3 {
    font-size: 20px;
  }

  .closecard {
    right: 16px;
    bottom: 10px;
  }
}

@media only screen and (min-width: 1267px) and (max-width: 1370px) {
  .item,
  .item .back {
    height: 11em;
    width: 17.5em;
    margin-bottom: 25px;
    padding: 15px;
    margin-right: 20px;
  }

  .card-header {
    width: 100%;
  }

  .card-desc {
    margin-left: 0;
  }

  .card-header h3 {
    font-size: 19px;
    white-space: nowrap;
  }

  .card-header h3 span {
    font-size: 12px;
  }

  .allCats {
    width: 100%;
  }

  .allCats p {
    font-size: 10px;
    margin-right: 8px;
  }

  .frontBottom {
    width: 100%;
  }

  .back h3 {
    font-size: 19px;
  }

  .card-header img {
    width: 40px;
    height: 40px;
  }

  .street {
    font-size: 14px;
  }

  .ph,
  .contactemail {
    font-size: 14px;
  }

  .closecard {
    right: 17px;
    bottom: 10px;
  }
}

@media only screen and (min-width: 1060px) and (max-width: 1266px) {
  .item,
  .front,
  .back {
    width: 20.5em;
    height: 12.5em;
    margin-right: 32px;
    padding: 15px;
  }

  .card-header {
    width: 100%;
  }
  .card-header h3 {
    font-size: 21px;
  }

  .card-header h3 span {
    font-size: 14px;
  }

  .allCats {
    margin-top: 25px;
    width: 100%;
    height: 30%;
  }

  .frontBottom {
    width: 100%;
  }

  .allCats p {
    font-size: 12px;
  }

  .back h3 {
    font-size: 21px;
  }

  .street {
    font-size: 15px;
  }

  .ph,
  .contactemail {
    font-size: 15px;
  }

  .closecard {
    right: 15px;
    bottom: 10px;
  }
}

@media only screen and (max-width: 769px) {
  .item,
  .front,
  .back {
    width: 22em;
    height: 14em;
    margin-left: 18px;
    margin-right: 10px;
  }

  .card-header h3 {
    font-size: 20px;
  }

  .card-header p {
    font-size: 15px;
  }

  .allCats {
    margin-top: 10px;
  }

  .allCats p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 426px) {
  .item,
  .front,
  .back {
    margin-right: 0;
    margin-left: 0;
  }

  .card-header {
    width: 100%;
  }

  .card-header h3 {
    font-size: 21px;
  }

  .back h3 {
    font-size: 21px;
  }

  .card-desc {
    margin-left: 0;
  }

  .allCats,
  .frontBottom {
    width: 100%;
  }

  .closecard {
    right: 17px;
  }
}

@media only screen and (max-width: 376px) {
  .item,
  .front,
  .back {
    height: 14em;
    width: 21em;
    padding: 20px;
  }

  .card-header {
    width: 100%;
  }

  .card-header h3 {
    font-size: 20px;
  }

  .allCats {
    width: 100%;
    height: 35%;
  }

  .allCats p {
    font-size: 11px;
  }

  .frontBottom {
    width: 100%;
  }

  .back h3 {
    font-size: 20px;
  }

  .street,
  .ph,
  .contactemail {
    font-size: 15px;
  }

  .closecard {
    right: 21px;
    bottom: 17px;
  }
}

@media only screen and (height: 900px) and (width: 1600px) {
  .item,
  .front,
  .back {
    height: 14em;
    width: 21em;
    padding: 20px;
  }

  .card-desc {
    margin-left: 0;
  }

  .card-header h3 {
    font-size: 21px;
  }
}
