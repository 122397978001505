.footer {
  background-color: #020d44;
  width: 100%;
  height: 100vh;
  font-family: "Montserrat", sans-serif;
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 50px;
}

/* .footer-padding-down {
  padding-top: 60px;
} */
ul li {
  list-style-type: none;
}
a {
  text-decoration: none;
  cursor: pointer;
}
/* .title-footer {
  height: 80vh;
} */
.title-footer h1 {
  padding: 0;
  margin: 0;
  margin-left: 90px;
  padding-top: 177px;
  color: white;
  font-size: calc(30px + (70 - 30) * ((100vw - 769px) / (1920 - 769)));
  font-weight: 900;
  line-height: 91px;
}
.display-flex {
  display: flex;
  /* margin-top: 400px; */
  /* margin-top: calc(20% + 60px); */
}
.social-icon {
  padding-left: 5%;
  width: 33%;
  margin-top: 1%;
  display: flex;
  align-items: center;
}
.social-icon ul {
  padding-top: 40px;
  display: flex;
}
.social-icon ul li {
  margin-left: 20px;
}
.contact-info ul li {
  color: white;
  margin-left: 2%;
  font-weight: bold;
  font-size: 34px;
  font-size: calc(18px + (34 - 18) * ((100vw - 769px) / (1920 - 769)));
  line-height: 74px;
}
.fbIcon {
  margin-top: -9px;
  margin-left: 15%;
}
.linkedinIcon {
  margin-top: -5px;
}
.contact-info {
  /* margin-left: 9%; */
  margin-top: -2%;
  width: 33%;
}
.social-media {
  color: white;
  /* margin-left: 9%; */
  margin-top: -9.5%;
  width: 33%;
}
.social-media ul li a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  /* font-size: 34px; */
  font-size: calc(18px + (34 - 18) * ((100vw - 769px) / (1920 - 769)));
  line-height: 74px;
}

#footermarketer {
  text-decoration: underline;
}
.img1 {
  background-image: url("../Footer/FooterElement.png");
  padding: 20%;
  top: 0;
  right: 0.5%;
  background-repeat: no-repeat;
  position: absolute;
}
.footerapliciraj-tel,
.footermarketer-tel {
  display: none;
}
/* @media (max-width: 768px) {
  .footermarketer-pc,
  .footerapliciraj-pc {
    display: none;
  }
  .footermarketer-tel {
    text-decoration: underline;
  }
  .footerapliciraj-tel,
  .footermarketer-tel {
    display: block;
  }
}
@media (max-width: 768px) {
  .display-flex {
    display: flex;
  }
  .footer {
    width: 100vw;
    height: 100vh;
    background-color: #020d44;
  }
  .title-footer h1 {
    padding-top: 0;
  }
  .social-icon {
    padding-top: 17%;
    padding-left: 9%;
  }
  .social-media {
    margin-top: -28%;
    margin-left: -21%;
    width: 41vw;
  }
  .social-media ul li a {
    line-height: 48px;
  }
  .contact-info {
    margin-top: 0%;
    margin-left: -41%;
  }
  .contact-info ul li {
    line-height: 48px;
  }
  #footermarketer1 {
    text-decoration: underline;
  }
}

@media (max-width: 426px) {
  .footer {
    width: 100vw;
    height: 100vh;
    background-color: #020d44;
  }
  
  #footermarketer1 {
    text-decoration: underline;
  }
  .img1 {
    display: none;
  }
  
  .social-media {
    margin-top: -37%;
    margin-left: -28%;
    width: 100vw;
    height: max-content;
  }
  .social-media ul li a {
    line-height: 48px;
  }
  .contact-info {
    padding-top: 6%;
    width: 100vw;
    height: max-content;
    margin-left: -97%;
  }
  .contact-info ul li {
    line-height: 48px;
  }

  .social-icon {
    width: 35vw;
    height: max-content;
    padding-top: 30%;
    padding-left: 1%;
  }
  .title-footer h1 {
    padding: 0;
    margin: 0;
    color: white;
    font-size: 70px;
  }
}
@media (max-width: 426px) {
  .contact-info {
    padding-top: 12%;
    width: 100vw;
    height: max-content;
    margin-left: -97%;
  }
  .social-icon {
    width: 44vw;
    height: max-content;
    padding-top: 30%;
    padding-left: 1%;
  }
  .social-media {
    margin-top: -37%;
    margin-left: -36%;
    width: 100vw;
    height: max-content;
  }
} */
@media (max-width: 770px) {
  .img1 {
    display: none;
  }
  .footer {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 10px;
    padding-bottom: 100px;
  }

  .footer-padding-down {
    padding-top: 80px;
  }

  .footer h1 {
    margin: 0;
    padding: 0;
  }

  .display-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    height: 100%;
  }

  .social-media {
    color: white;
    order: 1;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-top: 10%;
  }

  .contact-info {
    order: 2;
    width: 100%;
    margin-top: 10%;
  }

  .social-icon {
    order: 3;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-top: 12%;
  }

  .contact-info ul li {
    margin: 0;
    line-height: 40px;
  }
  .social-icon ul li {
    margin-left: 0;
    margin-right: 20px;
  }
  .social-media ul li a {
    line-height: 50px;
  }

  .social-icon ul {
    padding-top: 0;
  }
}

@media (max-width: 426px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 10px;
    height: 100vh;
    padding-bottom: 110px;
  }

  .footer-padding-down {
    padding-top: 70px;
    padding-bottom: 30px;
  }

  .footer h1 {
    margin: 0;
    padding: 0;
  }

  .display-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    height: 80%;
  }

  .social-media {
    color: white;
    order: 1;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .contact-info {
    order: 2;
    width: 100%;
  }

  .social-icon {
    order: 3;
    padding: 0;
    margin: 0;
    width: 50%;
  }

  .contact-info ul li {
    margin: 0;
  }
  .social-icon ul li {
    margin-left: 0;
    margin-right: 20px;
  }
  .social-media ul li a {
    line-height: 50px;
  }
  .social-icon ul {
    padding-top: 0;
    display: flex;
    align-items: center;
    height: 100px;
  }

  .footer h1 {
    font-size: 34px;
  }

  .social-media {
    margin-top: 30px;
  }

  .contact-info ul li {
    font-size: 20px;
  }

  .social-media ul li a {
    font-size: 20px;
  }

  .social-media {
    margin-top: 00px;
  }

  .contact-info {
    margin-top: -18%;
  }

  .social-icon ul {
    margin-top: -100px;
  }
}

@media (max-width: 376px) {
  .footer-padding-down {
    padding-top: 70px;
    padding-bottom: 30px;
  }

  .contact-info ul li {
    line-height: 40px;
  }

  .social-icon {
    display: flex;
    align-items: center;
  }

  .social-icon ul {
    display: flex;
    align-items: center;
    height: 100px;
    margin-top: -120px;
  }

  .social-media {
    margin-top: 0;
  }

  .footer h1 {
    font-size: 34px;
  }

  .social-media {
    margin-top: 30px;
  }

  .contact-info ul li {
    font-size: 20px;
  }

  .social-media ul li a {
    font-size: 20px;
  }

  .footer {
    padding-bottom: 55px;
    justify-content: space-evenly;
    position: relative;
  }

  .title-footer {
    position: absolute;
    top: 3%;
  }

  .display-flex {
    position: absolute;
    top: 10%;
    justify-content: safe;
    height: 76%;
  }

  .social-icon ul li {
    margin-top: 10px;
    margin-right: 12px;
  }

  .footer-padding-down .display-flex {
    position: absolute;
    top: 14%;
  }
  .footer-padding-down .title-footer {
    position: absolute;
    top: 9%;
  }
}
/* ovde treba proverka za 1600 i 1501!! */
@media only screen and (min-width: 1600px) and (max-width: 1699px) {
  .social-media {
    color: white;
    /* margin-left: 9%; */
    margin-top: -10.5%;
    width: 33%;
  }
}
@media only screen and (min-width: 1501px) and (max-width: 1599px) {
  .social-media {
    color: white;
    /* margin-left: 9%; */
    margin-top: -12.5%;
    width: 33%;
  }

  .social-icon {
    margin-top: -2%;
  }
  /* ovde treba proverka za 1600 i 1501!! */
}
@media only screen and (min-width: 1431px) and (max-width: 1500px) {
  .title-footer h1 {
    padding: 0;
    margin: 0;
    margin-left: 90px;
    padding-top: 137px;
    line-height: 81px;
  }
  .social-media {
    color: white;
    /* margin-left: 9%; */
    margin-top: -12.5%;
    width: 33%;
  }
}
@media only screen and (min-width: 1365px) and (max-width: 1430px) {
  .title-footer h1 {
    padding: 0;
    margin: 0;
    margin-left: 90px;
    padding-top: 120px;
    line-height: 81px;
  }
  .social-media {
    color: white;
    /* margin-left: 9%; */
    margin-top: -12.5%;
    width: 33%;
  }
}
@media only screen and (min-width: 1267px) and (max-width: 1364px) {
  .title-footer h1 {
    padding: 0;
    margin: 0;
    margin-left: 90px;
    padding-top: 50px;
    line-height: 81px;
  }
  .social-media {
    color: white;
    /* margin-left: 9%; */
    margin-top: -12.5%;
    width: 33%;
  }
}
@media only screen and (min-width: 1060px) and (max-width: 1266px) {
  .title-footer h1 {
    padding: 0;
    margin: 0;
    margin-left: 90px;
    padding-top: 50px;
    line-height: 81px;
  }
  .social-media {
    color: white;
    /* margin-left: 9%; */
    margin-top: -15.5%;
    width: 33%;
  }
}
