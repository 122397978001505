body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

/* a {
  color: #000;
} */

.header {
  height: 90px;
  /* background-color: #fff; */
  width: 100%;
  z-index: 22;
  position: absolute;
  top: 0;
  /* box-shadow: 0 8px 15px 0px rgba(0, 0, 0, 0.2); */
}

.header ul {
  margin: 0;
  padding-top: 30px;
  list-style: none;
  overflow: hidden;
  /* background-color: #fff; */
  padding-right: 40px;
}

.header li a {
  color: #020d44;
  font-weight: bold;
  display: block;
  padding: 20px 20px;
  text-decoration: none;
  font-size: 14px;
}
.header li a:hover {
  color: #052bf0;
}

.header .logo {
  float: left;
  text-decoration: none;
  margin-top: 24px;
  margin-left: 100px;
}

.logo img {
  height: 55px;
  width: 55px;
}

.menu {
  display: flex;
}
/* 
.navIMG {
  display: none;
} */

.header .menu {
  z-index: 99999999999;
  /* text-align: center; */
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 40px 60px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 35px;
}

.header .menu-icon .navicon {
  top: 5px;
  height: 3px;
  width: 35px;
}
.header .menu-icon .navicon:before {
  top: 10px;
}

.header .menu-icon .navicon:after {
  top: -10px;
}

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  padding-bottom: 60px;
  max-height: 100vh;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ img {
  height: 20vh;
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

.hidden-btn {
  display: none;
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .header .logo {
    margin-left: 80px;
  }
}

@media (min-width: 900px) and (max-width: 1400px) {
  .header .logo {
    margin-left: 70px;
  }
}

@media (max-width: 918px) {
  .header li a {
    font-size: 10px;
  }
}
@media (max-width: 873px) {
  .header li a {
    font-size: 10px;
  }
  .header ul {
    padding-right: 25px;
  }
}
@media (max-width: 817px) {
  .header li a {
    font-size: 10px;
  }
}
@media (max-width: 808px) {
  .header li a {
    /* font-size: 19px; */
  }
  .header ul {
    padding-right: 20px;
  }
}

@media (max-width: 802px) {
  .header li a {
    /* font-size: 14px; */
  }
}

@media (min-width: 770px) {
  .header li {
    float: left;
  }
  .header li a {
    padding: 10px 5px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
  /* .header .logo {
    margin-left: 40px;
  } */
}

.desktop-menu {
  display: block;
  display: flex;
}

.mobile-menu {
  display: none;
}

@media (max-width: 769px) {
  .header {
    background-color: white;
    position: sticky;
    top: 0;
    box-shadow: 0 8px 15px 0px rgba(0, 0, 0, 0.2);
  }

  .header ul {
    background-color: white;
    padding-top: 0;
  }

  .header li a {
    text-align: center;
    font-size: 20px;
  }
  .menu li {
    margin-top: 50px;
  }
  .hidden-btn {
    display: block;
    width: max-content;
  }
  .desktop-menu {
    display: none;
  }
  .mobile-menu {
    display: block;
  }
  .menu {
    display: block;
    height: 100vh;
  }
  .hidden {
    display: none;
  }
  .header li a {
    font-size: 20px;
  }
  .header .menu {
    padding: 0px 60px 0px 60px;
  }

  .header .menu-icon {
    padding: 40px 80px;
  }

  .hire-marketer {
    padding: 0 24px;
    background-color: #052bf0;
    border: 1px solid #052bf0;
  }

  .apply-as-a-marketer {
    padding: 0 24px;
    background-color: white;
    color: black;
    border: 1px solid #052bf0;
  }
}

@media (max-width: 426px) {
  .header {
    height: 70px;
  }
  .header .menu {
    padding: 0;
    padding-bottom: 0px;
  }
  .header .logo {
    margin-left: 30px;
    margin-top: 10px;
  }
  .header .menu-icon {
    padding: 28px 30px;
  }

  .header li a {
    font-size: 16px;
    padding: 15px 20px;
    text-decoration: none;
    margin-bottom: 20px;
    word-break: keep-all;
    white-space: nowrap;
  }

  .menu {
    position: relative;
    background-image: url("../../Assets/Images/menupic.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom left;
  }
}

@media (max-width: 376px) {
  .menu li {
    margin-top: 35px;
  }
}
