.apply-as-a-marketer {
  padding: 0 24px;
  background-color: #020d44;
  border: 1px solid #052bf0;
}

.apply-as-a-marketer:hover {
  background-color: transparent;
}

.apply-as-a-marketer a {
  color: white !important;
}
.apply-as-a-marketer a:hover {
  color: #052bf0 !important;
}

@media (max-width: 769px) {
  .apply-as-a-marketer {
    border: none;
    text-decoration: underline;
    background-color: white;
    border: none !important;
  }
  .apply-as-a-marketer a {
    color: #020d44 !important ;
  }
  .apply-as-a-marketer a:hover {
    border: none;
  }
}
