@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap");

.map-container {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 40px;
  background-color: transparent;
}

.map-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 2px solid lightgray;
  padding-bottom: 130px;
  min-height: 100vh;
  position: relative;
}
.filters {
  width: 20%;
  height: 1000px;
  position: relative;
}

.fixed-filter {
  position: fixed;
  top: 10px;
}

.filter-header {
  width: 95%;
  margin-left: 10px;
  display: flex;
  padding-left: 0px;
  padding-bottom: 30px;
  border-bottom: 1px solid lightgray;
}
.btnBB {
  margin-left: 0px;
  margin-right: 50px;
  text-align: left;
  font-size: 18px;
  line-height: 1.24;
  height: fit-content;
  margin-bottom: 0;
}
.map-header {
  height: 72vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.map-header h1 {
  width: 100%;
  padding: 0;
  font-size: 66px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  color: #020d44;
}

.map-header p {
  margin-top: 25px;
  text-align: left;
  font-size: 28px;
  font-weight: normal;
  color: #020d44;
  line-height: 1.34;
}

.filters h3 {
  padding-left: 10px;
  margin-bottom: 37px;
  text-align: left;
  color: #052bf0;
  font-weight: bolder;
  font-size: 24px;
}

.filter-buttons .filterButton {
  width: fit-content;
}

.filter-buttons {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  font-size: 18px;
}

.map-cards {
  width: 76%;
  background-color: transparent;
  justify-content: flex-start;
  display: flex;
  flex-flow: row wrap;
  min-height: 500px;
  align-content: flex-start;
  position: relative;
}

.move-cards-left {
  margin-left: 24%;
}

.imgonfilter {
  position: absolute;
  right: -100px;
  top: -450px;
  z-index: 999;
}

.showmore {
  position: absolute;
  color: blue;
  cursor: pointer;
  left: 41%;
  bottom: -60px;
  font-size: 24px;
  font-weight: 600;
  text-decoration: underline;
}

.hideshow {
  display: none;
}

.main-wrap {
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
}

.main-wrap .picpic {
  position: absolute;
  right: 0;
  top: 0;
}

.ctacard h1 {
  font-size: 74px;
  font-weight: bolder;
  padding: 0;
  margin: 30px 0 30px 0;
}

.ctacard {
  width: 90%;
  margin: 0 auto;
  text-align: left;
  position: relative;
  padding-bottom: 130px;
  color: #020d44;
  padding-top: 60px;
}

.ctacard p {
  font-size: 24px;
  font-weight: 500;
  color: #020d44;
}

.ctacard button {
  position: absolute;
  top: 50%;
  right: 10%;
  font-size: 24px;
  color: white;
  padding: 20px;
  background-color: #052bf0;
  border: none;
  transform: translateY(-50%);
  cursor: pointer;
  font-weight: 700;
}

.ctacard button:hover {
  background-color: #ff0b53;
}

.map-circle {
  background-image: url("../../Assets/Images/circle.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  height: 200px;
  width: 200px;
  position: absolute;
  left: -110px;
  top: 50%;
}

/* @media only screen and (min-width: 1475px) and (max-width: 1674px) {
  .btnBB {
    font-size: 14px;
    margin-right: 25px;
  }

  .filter-header {
    margin-left: 0;
    width: 100%;
  }
} */
@media only screen and (min-width: 1600px) and (max-width: 1699px) {
  .map-container {
    width: 92%;
  }

  .filters {
    width: 24%;
  }

  .filter-header {
    width: 95%;
  }

  .map-cards {
    width: 76%;
  }
}

@media only screen and (min-width: 1501px) and (max-width: 1599px) {
  .map-container {
    width: 93%;
  }

  .filters {
    width: 25%;
  }

  .filter-header {
    width: 94%;
  }

  .map-cards {
    width: 75%;
  }

  .btnBB {
    font-size: 16px;
    margin-left: 10px;
  }

  .ctacard button {
    top: 60%;
  }
}

@media only screen and (min-width: 1431px) and (max-width: 1500px) {
  .map-container {
    width: 94%;
  }

  .filters {
    width: 25%;
  }

  .filter-header {
    width: 90%;
  }

  .btnBB {
    font-size: 15px;
    margin-left: 8px;
  }

  .map-cards {
    width: 77%;
  }

  .main-wrap .picpic {
    height: 500px;
    width: 400px;
    top: 100px;
  }
  .ctacard button {
    top: 65%;
  }
}

@media only screen and (min-width: 1365px) and (max-width: 1430px) {
  .filters {
    width: 24%;
  }

  .map-cards {
    width: 76%;
  }

  .filter-header {
    width: 95%;
  }

  .btnBB {
    font-size: 14px;
    margin-left: 5px;
  }

  .map-container {
    width: 93%;
  }

  .main-wrap .picpic {
    height: 500px;
    width: 400px;
    top: 100px;
  }

  .ctacard button {
    top: 65%;
  }
}

@media only screen and (min-width: 1267px) and (max-width: 1364px) {
  .map-container {
    width: 95%;
  }

  .filters {
    width: 24%;
  }

  .map-cards {
    width: 76%;
  }

  .filter-header {
    width: 90%;
  }

  .btnBB {
    margin-left: 0px;
    font-size: 13px;
  }

  .main-wrap .picpic {
    height: 450px;
    width: 350px;
    top: 100px;
  }

  .map-header h1 {
    font-size: 60px;
  }

  .map-header p {
    font-size: 26px;
  }
  .ctacard button {
    top: 65%;
  }
}

@media only screen and (min-width: 1060px) and (max-width: 1266px) {
  .map-container {
    width: 97%;
  }

  .map-content {
    width: 100%;
  }
  .filters {
    width: 29%;
  }

  .map-cards {
    width: 80%;
    margin-left: 35px;
  }

  .filter-header {
    width: 90%;
  }

  .btnBB {
    font-size: 14px;
    margin-left: 2px;
  }

  .filter-buttons {
    margin-left: 8px;
  }

  .main-wrap .picpic {
    height: 450px;
    width: 350px;
    top: 100px;
  }

  .map-header h1 {
    font-size: 60px;
  }

  .map-header p {
    font-size: 26px;
  }

  .ctacard button {
    top: 85%;
    left: 35%;
  }
}

@media only screen and (max-width: 769px) {
  .showmore {
    left: 40%;
  }
  .main-wrap {
    width: 100%;
  }

  .map-container {
    width: 100%;
  }
  .map-content {
    width: 100%;
  }

  .map-header {
    width: 100%;
    padding: 20px;
    height: 62vh;
  }

  .map-cards {
    margin-left: 0px;
    width: auto;
    margin: 0 auto;
    justify-content: flex-start;
  }

  .main-wrap .picpic {
    height: 375px;
    width: 285px;
    top: 0px;
    z-index: -1;
  }

  .map-header h1 {
    font-size: 62px;
  }

  .map-header p {
    font-size: 24px;
  }

  .ctacard h1 {
    font-size: 50px;
    white-space: nowrap;
    margin: 0px 0 30px 0;
  }

  .ctacard {
    padding-top: 20px;
  }

  .ctacard p {
    font-size: 22px;
    font-weight: 500;
    color: #020d44;
  }

  .ctacard button {
    position: absolute;
    top: 80%;
    left: 60%;
    font-size: 20px;
    color: white;
    padding: 18px;
    margin-top: 10px;
    background-color: #052bf0;
    border: none;
    transform: translate(-80%, -60%);
    cursor: pointer;
  }

  .btnBB {
    font-size: 18px;
    width: 38%;
    padding: 0;
    margin: 0;
    margin-right: 25px;
  }

  .filter-header {
    width: 88%;
    display: flex;
    justify-content: flex-start;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .filter-cont {
    display: none;
    height: 0;
  }

  .filters {
    width: 0;
    height: 0;
    position: relative;
  }

  .filterBTNup,
  .filterBTNdown {
    position: fixed;
    bottom: 0;
    z-index: 99999;

    padding: 0;
    background-color: #052bf0;
    color: white;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 18px;
    padding: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 55px;
  }

  .btnDOWN,
  .btnUP {
    position: fixed;
    z-index: 99999;
    bottom: 0;
    padding: 0;
    background-color: #052bf0;
    color: white;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 18px;
    padding: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 55px;
    animation: slidedown 0.5s;
  }

  @keyframes slidedown {
    from {
      bottom: 100%;
    }
    to {
      bottom: 0;
    }
  }

  .btnUP {
    top: 0;
    animation: slideup 0.5s;
  }
  @keyframes slideup {
    from {
      bottom: 0;
      top: 100%;
    }
    to {
      bottom: 100%;
      top: 0;
    }
  }

  .arrowup,
  .arrowdown {
    color: white;
  }

  .toggledFilter,
  .closedFilter {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    animation: toggleFilter 0.5s;
    z-index: 9999;
    background-color: white;
    padding-top: 55px;
  }

  .closedFilter {
    height: 0;
    animation: closeFilter 0.5s;
    padding-top: 55px;
  }
  @keyframes toggleFilter {
    from {
      height: 0;
    }
    to {
      height: 100%;
    }
  }

  @keyframes closeFilter {
    from {
      height: 100%;
    }
    to {
      height: 0;
    }
  }
}
@media only screen and (max-width: 426px) {
  .map-header h1 {
    font-size: 35px;
    width: 100%;
    margin-top: 145px;
    z-index: 0;
    text-align: left;
  }

  .map-header p {
    width: 90%;
    font-size: 18px;
  }

  .main-wrap {
    width: 100%;
  }

  .main-wrap .picpic {
    height: 280px;
    width: 200px;
    object-fit: cover;
    right: 0;
    top: 0;
  }

  .map-container {
    width: 100%;
  }

  .showmore {
    margin-left: -60px;
  }

  .map-header {
    width: 90%;
    margin: 0 auto;
    height: 55vh;
  }

  .map-content {
    width: 100%;
    justify-content: unset;
  }

  .ctacard button {
    top: 85%;
    left: 13%;
    transform: translateY(-13%);
  }

  .ctacard h1 {
    white-space: normal;
  }

  .filter-cont {
    display: none;
    height: 0;
  }

  .filters {
    width: 0;
    height: 0;
    position: relative;
  }

  .filterBTNup,
  .filterBTNdown {
    position: fixed;
    bottom: 0;
    padding: 0;
    background-color: #052bf0;
    color: white;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 18px;
    padding: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 99999;
    height: 55x;
  }

  .btnDOWN,
  .btnUP {
    z-index: 99999;
    position: fixed;
    bottom: 0;
    padding: 0;
    background-color: #052bf0;
    color: white;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 20px;
    padding: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 55px;
    animation: slidedown 0.5s;
  }

  @keyframes slidedown {
    from {
      bottom: 100%;
    }
    to {
      bottom: 0;
    }
  }

  .btnUP {
    top: 0;
    animation: slideup 0.5s;
  }
  @keyframes slideup {
    from {
      bottom: 0;
      top: 100%;
    }
    to {
      bottom: 100%;
      top: 0;
    }
  }

  .arrowup,
  .arrowdown {
    color: white;
  }

  .toggledFilter,
  .closedFilter {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    animation: toggleFilter 0.5s;
    background-color: white;
    border: none;
  }

  .closedFilter {
    height: 0;
    animation: closeFilter 0.5s;
    padding-top: 55px;
  }
  @keyframes toggleFilter {
    from {
      height: 0;
    }
    to {
      height: 100%;
    }
  }

  @keyframes closeFilter {
    from {
      height: 100%;
    }
    to {
      height: 0;
    }
  }

  .filter-header {
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
  }

  .btnBB {
    font-size: 17px;
    margin-bottom: 0;
  }

  .filter-buttons {
    width: 82%;
    margin: 20px auto;
  }

  .map-cards {
    width: 90%;
    margin: 0 auto;
    padding: 0;
    justify-content: center;
  }

  .showmore {
    margin-left: 0;
    left: 33%;
  }

  .ctacard h1 {
    font-size: 36px;
  }

  .ctacard p {
    font-size: 20px;
  }

  .ctacard button {
    top: 75%;
    left: 12%;
    width: 280px;
    height: 48px;
    padding: 0;
  }

  .showmore {
    left: 28%;
  }
}

@media only screen and (max-width: 376px) {
  .main-wrap .picpic {
    height: 180px;
    width: 160px;
    object-fit: cover;
    right: 0;
    top: 0;
  }

  .map-header h1 {
    margin-top: 75px;
  }

  .map-header {
    padding: 5px;
    height: 50vh;
  }

  .btnBB {
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .filter-buttons {
    margin-top: 8px;
    width: 83.5%;
  }

  .ctacard button {
    left: 8%;
  }

  .showmore {
    left: 25%;
  }
}
