* {
  margin: 0;
  padding: 0;
}

.hero-wrap {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

/* .hero {
  position: absolute;
  right: 0;
  height: 100vh;
} */

.hero-wrap {
  background-image: url("./Hero.png");
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
}

.heroMobile {
  display: none;
}

.heroTablet {
  display: none;
}

.hero-wrap h1 {
  position: absolute;
  top: 27%;
  text-align: left;
  left: 6%;
  font-size: 70px;
  font-size: calc(30px + (70 - 30) * ((100vw - 769px) / (1920 - 769)));
  font-weight: 900;
  color: #020d44;
}

.hero-wrap p {
  position: absolute;
  /* font-size: 30px; */
  font-size: calc(18px + (28 - 18) * ((100vw - 769px) / (1920 - 769)));
  font-weight: 900;
  text-align: left;
  top: 51%;
  left: 6%;
  width: 37%;
  font-weight: 400;
  color: #020d44;
}

.btn-space {
  position: absolute;
  display: flex;
  justify-content: space-between;
  height: 55px;
  width: 40%;
  background-color: cadetblue;
  bottom: 25%;
  left: 6%;
  border: none;
  background-color: transparent;
}

.buttonHireMarketier {
  background-color: #052bf0;
  color: white;
  font-size: calc(15px + (24 - 15) * ((100vw - 769px) / (1920 - 769)));
  width: 48%;
  font-weight: 600;
  border: none;
  cursor: pointer;
  text-align: center;
  word-break: keep-all;
  white-space: nowrap;
}

.buttonHireMarketier:hover {
  background-color: #ff0b53;
}
.buttonApplyAsMarketier {
  background-color: white;
  color: #020d44;
  font-size: calc(15px + (24 - 15) * ((100vw - 769px) / (1920 - 769)));
  text-align: center;
  border: none;
  cursor: pointer;
  width: 44%;
  word-break: keep-all;
  white-space: nowrap;
}

@media only screen and (max-width: 769px) {
  .hero {
    display: none;
  }

  .hero-wrap {
    background-image: none;
    overflow: hidden;
  }
  .heroTablet {
    display: block;
    position: absolute;
    bottom: 55px;
    right: 0;
    height: 100vh;
  }

  .heroMobile {
    display: none;
  }

  .btn-space {
    left: 6%;
    bottom: 30%;
    width: 62%;
    flex-direction: column;
    height: 110px;
  }
  .buttonHireMarketier {
    font-size: 18px;
    font-weight: 700;
    height: 50px;
  }

  .buttonApplyAsMarketier {
    font-size: 18px;
    font-weight: 700;
    height: 50px;
    width: 48%;
  }
  .hero-wrap h1 {
    position: absolute;
    top: 27%;
    text-align: left;
    left: 6%;
    font-size: 45px;
    font-weight: 900;
    color: #020d44;
  }

  .hero-wrap p {
    position: absolute;
    font-size: 20px;
    font-weight: 900;
    text-align: left;
    top: 51%;
    left: 6%;
    width: 80%;
    font-weight: 350;
    color: #020d44;
  }
  .AppLanding p {
    margin-left: 0;
  }
}

@media screen and (max-width: 426px) {
  .backgroundServices {
    padding-bottom: 20px;
  }
  .hero {
    display: none;
  }
  .hero-wrap {
    margin-top: -30%;
    height: 100vh;
  }

  .heroTablet {
    display: none;
  }
  .heroMobile {
    display: block;
    position: absolute;
    bottom: 0px;
    right: 0;
  }
  /* .AppLanding h1 {
    margin-left: 50px;
    margin-top: -75vh;
    font-size: 34px;
    width: 70%;
  } */

  .AppLanding p {
    /* margin-left: 50px; */
    margin-top: 20px;
    font-size: 18px;
    font-weight: 900;
    width: 80%;
    font-weight: 200;
  }
  .buttonHireMarketier {
    height: 50px;
    width: 100%;
  }

  .btn-space {
    flex-direction: column;
    height: 90px;
    bottom: 23%;
  }

  /* .Landing {
    background-image: url("./herom.png");
    height: 100vh;
    background-size: cover;
  } */
  #arrowAnim {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 45%;
    right: -21px;
    transform: rotate(180deg);
  }

  .arrow {
    width: 5vw;
    height: 5vw;
    border: 2.5vw solid;
    border-color: #020d44 transparent transparent #020d44;
    transform: rotate(-45deg);
  }

  .arrowSliding {
    position: absolute;
    -webkit-animation: slide 4s linear infinite;
    animation: slide 4s linear infinite;
  }

  .delay1 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  .delay2 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
  .delay3 {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
  }

  @-webkit-keyframes slide {
    0% {
      opacity: 0;
      transform: translateX(15vw);
    }
    20% {
      opacity: 1;
      transform: translateX(9vw);
    }
    80% {
      opacity: 1;
      transform: translateX(-9vw);
    }
    100% {
      opacity: 0;
      transform: translateX(-15vw);
    }
  }
  @keyframes slide {
    0% {
      opacity: 0;
      transform: translateX(15vw);
    }
    20% {
      opacity: 1;
      transform: translateX(9vw);
    }
    80% {
      opacity: 1;
      transform: translateX(-9vw);
    }
    100% {
      opacity: 0;
      transform: translateX(-15vw);
    }
  }
}

.buttonApplyAsMarketier:hover {
  color: #052bf0;
}

@media screen and (max-width: 376px) {
  .hero-wrap h1 {
    font-size: 38px;
  }

  .hero-wrap p {
    top: 35%;
  }

  .hero-wrap h1 {
    top: 15%;
  }

  .buttonApplyAsMarketier {
    width: 260px;
    bottom: 30%;
  }

  .btn-space {
    flex-direction: column;
    height: 100px;
    bottom: 30%;
  }

  .heroMobile {
    height: auto;
    position: absolute;
    bottom: 15px;
    right: 0;
  }
}
