.hire-marketer {
  padding: 0 24px;
  background-color: #052bf0;
  border: 1px solid #052bf0;
  margin-right: 5px;
  margin-left: 5px;
}

.hire-marketer:hover {
  background-color: #ff0b53;
  border: 1px solid #ff0b53;
}

.hire-marketer a {
  color: white !important;
}

@media (max-width: 767px) {
  .hire-marketer:hover {
    background-color: #052bf0;
  }
  .hire-marketer {
    border: none !important;
  }
  .hire-marketer-mobile {
    margin-bottom: 30px;
  }
}

@media (max-width: 426px) {
  .hire-marketer-mobile {
    width: 280px;
    height: 48px;
    margin: 0 auto;
    font-size: 20px;
  }

  .hire-marketer-mobile a {
    margin-right: 0;
  }
}
