.App {
  text-align: center;
}

.gradientBackground h1 {
  color: #020d44;
  font-weight: 950;
  /* font-size: 55px; */
  font-size: calc(30px + (74 - 30) * ((100vw - 769px) / (1920 - 769)));
  text-align: left;
  width: 70vw;
  padding: 2.5rem 7rem 0rem;
  margin: 0;
}

.num-bottom {
  position: absolute;
  bottom: 0;
  left: 20%;
  width: 50%;
  height: fit-content;
  pointer-events: none;
}

.numzero {
  position: fixed;
  bottom: 40px;
  left: 30%;
  height: 200px;
  width: 180px;
  z-index: 9999;
  margin-right: 20px;
  pointer-events: none;
}

.number {
  position: absolute;
  bottom: 40px;
  left: 39%;
  height: 200px;
  width: 160px;
  pointer-events: none;
  z-index: 9999;
}

.num1 {
  height: 200px;
  pointer-events: none;
  width: 100px;
}

.stepsText {
  color: #020d44;
  text-align: left;
  font-size: 37px;
  font-size: calc(15px + (44 - 15) * ((100vw - 769px) / (1920 - 769)));
  padding: 4rem 7rem;
  width: calc(55% - 15px);
}

.none {
  display: none;
}

.gradientBackground .buttonHireMarketier {
  position: absolute;
  /* left: 6%;
  bottom: 41%;  */
  margin: 1rem 7rem;
  width: calc(18% - 5px);
  /* margin-left: calc(1% - 15px); */
  /* height: 65px; */
  height: calc(8% - 5px);
  background-color: #052bf0;
  color: white;
  /* font-size: 24px; */
  font-size: calc(15px + (24 - 15) * ((100vw - 769px) / (1920 - 769)));
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.gradientBackground .buttonHireMarketier:hover {
  background-color: #ff0b53;
}

.stepnum {
  color: white;
  font-weight: 900;
}

.fixedtext {
  top: 10%;
  left: 8vw;
  font-size: calc(21px + (21 - 21) * ((100vw - 769px) / (1920 - 769)));
  color: #ff0b53;
}

.slide-background {
  box-shadow: 20px 25px 30px rgba(0, 0, 0, 0.25);
}

.gradientBackground {
  background-image: linear-gradient(
    87deg,
    white 40%,
    lightgray 55%,
    transparent 70%
  );
  /* background: rgba(255, 255, 255, 0.7); */
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}

.bsDAVG {
  position: absolute;
  bottom: 1% !important;
  left: 30% !important;
}
.bsDAVG {
  position: absolute;
  bottom: 10px;
  left: 29%;
}

.imgslide1 {
  background: url("../../Assets/Images/cekor-1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  position: relative;
}

.imgslide2 {
  background: url("../../Assets/Images/cekor-2.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  position: relative;
}

.imgslide3 {
  background: url("../../Assets/Images/cekor-3.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  position: relative;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .step-num {
    margin-left: -210px;
    transform: scale(0.8);
    margin-bottom: -50px;
  }

  .zeroFixed {
    position: fixed;
    bottom: 10px;
    font-weight: 900;
    left: 35%;
    z-index: 9999999;
  }
  /* .gradientBackground .buttonHireMarketier {
    left: 7%;
    bottom: 40%;
  } */
  .gradientBackground {
    background-image: linear-gradient(
      87deg,
      white 40%,
      lightgray 50%,
      transparent 70%
    );
    height: 100vh;
  }

  .gradientBackground h1 {
    width: 90%;
  }

  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 40px;
    left: 30%;
    height: 200px;
    width: 180px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 40px;
    left: 42%;
    height: 200px;
    width: 160px;
    z-index: 9999;
  }

  .num1 {
    height: 200px;
    width: 100px;
  }
}

@media only screen and (min-width: 1501px) and (max-width: 1599px) {
  .step-num {
    margin-left: -210px;
    transform: scale(0.8);
    bottom: 0;
  }
  /* .gradientBackground .buttonHireMarketier {
    left: 7%;
    bottom: 35%;
  } */

  .gradientBackground {
    background-image: linear-gradient(
      87deg,
      white 40%,
      lightgray 50%,
      transparent 70%
    );
    height: 100vh;
  }
  .gradientBackground h1 {
    width: 90%;
  }

  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 40px;
    left: 30%;
    height: 170px;
    width: 160px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 40px;
    left: 42%;
    height: 170px;
    width: 160px;
    z-index: 9999;
  }

  .num1 {
    height: 170px;
    width: 90px;
  }
}
@media only screen and (min-width: 1431px) and (max-width: 1500px) {
  .step-num {
    margin-left: -230px;
    margin-bottom: -40px;
    transform: scale(0.8);
  }
  /* .gradientBackground .buttonHireMarketier {
    left: 8%;
    bottom: 48%;
  } */

  .gradientBackground {
    background-image: linear-gradient(
      87deg,
      white 40%,
      lightgray 45%,
      transparent 70%
    );
    height: 100vh;
  }
  .gradientBackground h1 {
    width: 90%;
  }

  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 40px;
    left: 30%;
    height: 180px;
    width: 170px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 40px;
    left: 44%;
    height: 180px;
    width: 170px;
    z-index: 9999;
  }

  .num1 {
    height: 170px;
    width: 90px;
  }
}

@media only screen and (min-width: 1365px) and (max-width: 1430px) {
  .step-num {
    margin-left: -230px;
    margin-bottom: -80px;
    transform: scale(0.7);
  }
  /* .gradientBackground .buttonHireMarketier {
    left: 8.5%;
    bottom: 41%;
  } */

  .gradientBackground {
    background-image: linear-gradient(
      87deg,
      white 32%,
      lightgray 42%,
      transparent 70%
    );
    height: 100vh;
  }

  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 40px;
    left: 30%;
    height: 180px;
    width: 170px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 40px;
    left: 44%;
    height: 180px;
    width: 170px;
    z-index: 9999;
  }

  .num1 {
    height: 170px;
    width: 90px;
  }
  .gradientBackground h1 {
    width: 90%;
  }
}
@media only screen and (min-width: 1267px) and (max-width: 1364px) {
  .step-num {
    margin-left: -250px;
    margin-bottom: -100px;
    transform: scale(0.6);
  }

  /* .gradientBackground .buttonHireMarketier {
    left: 9%;
    bottom: 46%;
  } */

  .gradientBackground {
    background-image: linear-gradient(
      87deg,
      white 36%,
      lightgray 45%,
      transparent 70%
    );
    height: 100vh;
  }

  .gradientBackground h1 {
    width: 78%;
  }

  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 40px;
    left: 30%;
    height: 160px;
    width: 150px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 40px;
    left: 44%;
    height: 160px;
    width: 150px;
    z-index: 9999;
  }

  .num1 {
    height: 160px;
    width: 70px;
  }
}
@media only screen and (min-width: 1031px) and (max-width: 1266px) {
  .step-num {
    margin-left: -250px;
    margin-bottom: -100px;
    transform: scale(0.6);
  }
  .gradientBackground .buttonHireMarketier {
    width: 255px;
  }

  .gradientBackground {
    background-image: linear-gradient(
      87deg,
      white 50%,
      lightgray 55%,
      transparent 70%
    );
    height: 100vh;
  }
  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 40px;
    left: 30%;
    height: 170px;
    width: 160px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 40px;
    left: 44%;
    height: 170px;
    width: 160px;
    z-index: 9999;
  }

  .num1 {
    height: 170px;
    width: 80px;
  }
}

@media (max-width: 1030px) {
  .fixedtext {
    font-size: 0.8rem;
  }
  .gradientBackground h1 {
    font-size: 37px;
    width: 70vw;
    padding: 2.5rem 3rem 0rem;
  }
  .gradientBackground .buttonHireMarketier {
    margin: 2.5rem 3rem 0rem;
    width: 255px;
  }
  .stepsText {
    font-size: 25px;
    padding: 1rem 3rem;
    width: 70vw;
  }
  .fixedNum {
    margin-left: -12rem;
  }
  /* .stepnum {
    font-size: 11rem;
  } */
  .marketar {
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 770px) {
  .gradientBackground {
    background-image: linear-gradient(
      180deg,
      white 14%,
      lightgray 30%,
      transparent 70%
    );
    height: 100vh;
  }

  .imgslide1,
  .imgslide2,
  .imgslide3 {
    background-position: bottom right;
  }

  .gradientBackground .buttonHireMarketier {
    /* top: 32%;
    left: 6.5%; */
    width: 255px;
    font-size: 18px;
    font-weight: 700;
  }

  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 20px;
    left: 6%;
    height: 140px;
    width: 130px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 20px;
    left: 8%;
    height: 140px;
    width: 130px;
    z-index: 9999;
  }

  .num1 {
    height: 140px;
    width: 65px;
  }
  .gradientBackground h1 {
    width: 100%;
  }
}

@media (max-width: 426px) {
  .fixedtext {
    font-size: 0.8rem;
  }
  .gradientBackground h1 {
    font-size: 29px;
    width: 100vw;
    padding: 3.5rem 2rem 0rem;
  }

  .gradientBackground .buttonHireMarketier {
    margin: 3.5rem 2rem 0rem;
  }
  .stepsText {
    font-size: 25px;
    padding: 0rem 2rem;
    width: 100vw;
    margin-top: 30px;
  }
  .fixedNum {
    margin-left: -10rem;
  }
  .stepnum {
    font-size: 9rem;
    margin-left: -37px;
  }
  .marketar {
    margin: 3rem;
  }

  .bsDAVG {
    left: 65% !important;
  }
  .imgslide1,
  .imgslide2,
  .imgslide3 {
    background-position: bottom;
  }

  .gradientBackground {
    background-image: linear-gradient(
      180deg,
      white 40%,
      lightgray 48%,
      transparent 71%
    );
    height: 89vh;
    /* margin-top: -70px; */
  }

  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 20px;
    left: 6%;
    height: 100px;
    width: 90px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 20px;
    left: 14%;
    height: 100px;
    width: 90px;
    z-index: 9999;
  }

  .num1 {
    height: 100px;
    width: 45px;
  }
}

@media (max-width: 767px) {
  .bsDAVG {
    left: 60%;
  }
  .stepnum {
    font-size: 8.5rem;
  }
}
.zeromob {
  font-weight: bold;
}
@media (max-width: 426px) {
  .gradientBackground {
    background: rgba(255, 255, 255, 0);
    background-image: linear-gradient(
      180deg,
      white 45%,
      lightgray 53%,
      transparent 57%
    );
    height: 89;
    /* margin-top: -140px; */
  }

  .gradientBackground h1 {
    font-size: 29px;
    width: 100vw;
    padding: 3.5rem 2rem 0rem;
  }

  .gradientBackground .buttonHireMarketier {
    position: absolute;
    width: 255px;
    height: 45px;
    background-color: #052bf0;
    color: white;
    font-size: 20px;
    font-weight: 700;
    border: none;
    cursor: pointer;
  }
}

@media (max-width: 376px) {
  .stepsText {
    font-size: 18px;
    padding: 1rem 2rem;
    margin-top: 20px;
  }
  .gradientBackground {
    background: rgba(255, 255, 255, 0);
    background-image: linear-gradient(
      180deg,
      white 38%,
      lightgray 45%,
      transparent 57%
    );
    height: 85vh;
    /* margin-top: -140px; */
  }

  .gradientBackground h1 {
    font-size: 30px;
    padding: 3.5rem 2rem 0rem;
  }
  .gradientBackground .buttonHireMarketier {
    /* left: 9%;
    top: 48%; */
    color: white;
    font-size: 18px;
    width: 255px;
    font-weight: 700;
    height: 45px;
    left: 9%;
    margin-left: 0;
    margin-top: 0;
    border: none;
  }

  .buttonApplyAsMarketier {
    width: 260px;
    height: 35px;
    background-color: white;
    font-weight: 700;
    font-size: 17px;
    border: none;
    margin-left: 0px;
    margin-top: 0px;
    left: 6%;
    bottom: 30%;
  }

  .num-bottom {
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 50%;
    height: fit-content;
  }

  .numzero {
    position: fixed;
    bottom: 20px;
    left: 6%;
    height: 100px;
    width: 90px;
    z-index: 9999;
    margin-right: 20px;
  }

  .number {
    position: absolute;
    bottom: 20px;
    left: 19%;
    height: 100px;
    width: 90px;
    z-index: 9999;
  }

  .num1 {
    height: 100px;
    width: 45px;
  }
}
