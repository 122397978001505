.wrapper-main {
  width: 90%;
  margin: 0 auto;
  background: transparent;
}

.container-map {
  display: flex;
  width: auto;
  width: 100%;
  margin: 0 auto;
  background: #fafafa;
  height: auto;
  justify-content: space-between;
  background: transparent;
  border-bottom: 2px solid lightgray;
}

.main {
  display: flex;
  flex: 0 0 77%;
  height: auto;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: flex-start;
  position: relative;
  padding-bottom: 220px;
  margin-left: 5%;
}

.sidebar {
  flex: 0 0 20%;
  background: transparent;
}

.sidebar-filters h3 {
  padding-left: 10px;
  margin-bottom: 37px;
  text-align: left;
  color: #052bf0;
  font-weight: bolder;
  font-size: 24px;
}

.sidebar-filters {
  position: sticky;
  top: 0;
  height: auto;
  background: transparent;
}
.picpic {
  position: absolute;
  right: 0;
  top: 0;
  height: 660px;
  width: 460px;
}

.showmore {
  position: absolute;
  color: blue;
  cursor: pointer;
  left: 41%;
  bottom: 100px;
  font-size: 24px;
  font-weight: 600;
  text-decoration: underline;
}

.spinnermain {
  height: 50vh;
  width: 100%;
  background-image: url("../../Assets/Images/blue-hourglass.gif");
  background-attachment: local;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  color: #020d44;
  font-size: 32px;
  font-weight: bolder;
}

.spin {
  background-image: url("../../Assets/Images/spin.gif");
  height: 150px;
  width: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.err-msg {
  position: absolute;
  left: 15%;
  top: 10%;
  width: 100%;
  color: red;
}

.err-sm {
  top: 2%;
  left: 25%;
}

@media only screen and (min-width: 1600px) and (max-width: 1699px) {
  .sidebar {
    flex: 0 0 22%;
  }
  .filter-header {
    width: 95%;
    margin-left: 10px;
  }

  .container-map {
    width: 100%;
  }

  .main {
    flex: 0 0 77%;
  }

  .picpic {
    top: 50px;
    height: 620px;
    width: 420px;
  }
  .map-circle {
    height: 170px;
    width: 170px;
    position: absolute;
    left: -110px;
    top: 50%;
  }
}

@media only screen and (min-width: 1501px) and (max-width: 1599px) {
  .sidebar {
    flex: 0 0 19%;
  }

  .filter-header {
    width: 94%;
    margin-left: 0;
  }

  .picpic {
    top: 80px;
    height: 660px;
    width: 460px;
  }

  .main {
    flex: 0 0 77%;
    margin-left: 0px;
  }

  .btnBB {
    font-size: 16px;
    margin-left: 10px;
  }

  .ctacard button {
    top: 60%;
  }
  .map-circle {
    height: 170px;
    width: 170px;
    position: absolute;
    left: -110px;
    top: 50%;
  }

  .picpic {
    top: 40px;
    height: 590px;
    width: 390px;
  }
}

@media only screen and (min-width: 1431px) and (max-width: 1500px) {
  .wrapper-main {
    width: 93%;
  }
  .filters {
    width: 20%;
  }

  .filter-header {
    margin-left: 0;
  }
  .main {
    flex: 0 0 75%;
    margin-left: 14px;
  }
  .picpic {
    top: 30px;
    height: 590px;
    width: 390px;
  }
  .map-circle {
    height: 150px;
    width: 150px;
    position: absolute;
    left: -110px;
    top: 50%;
  }
}

@media only screen and (min-width: 1365px) and (max-width: 1430px) {
  .wrapper-main {
    width: 96%;
  }
  .sidebar {
    flex: 0 0 18%;
  }

  .main {
    flex: 0 0 74%;
    margin-left: 2%;
  }
  .filter-header {
    width: 95%;
    margin-left: 5px;
  }

  .btnBB {
    font-size: 14px;
    margin-left: 5px;
  }

  .map-container {
    width: 93%;
  }

  .ctacard button {
    top: 65%;
  }

  .picpic {
    top: 30px;
    height: 600px;
    width: 400px;
  }
  .map-circle {
    height: 130px;
    width: 130px;
    position: absolute;
    left: -110px;
    top: 50%;
  }
}

@media only screen and (min-width: 1267px) and (max-width: 1364px) {
  .wrapper-main {
    width: 96%;
  }

  .filter-header {
    width: 90%;
    margin-left: 10px;
  }

  .sidebar {
    flex: 0 0 25%;
  }
  .main {
    flex: 0 0 75%;
    margin-left: 5px;
  }

  .picpic {
    top: 30px;
    height: 550px;
    width: 350px;
  }
  .map-circle {
    display: none;
  }
}

@media only screen and (min-width: 1060px) and (max-width: 1266px) {
  .wrapper-main {
    width: 95%;
  }

  .filter-header {
    width: 96%;
    margin-left: 6px;
  }

  .sidebar {
    flex: 0 0 30%;
  }

  .main {
    flex: 0 0 69%;
    margin-left: 25px;
  }

  .picpic {
    top: 100px;
    height: 600px;
    width: 400px;
  }
}

@media only screen and (max-width: 770px) {
  .wrapper-main {
    width: 100%;
  }

  .main {
    margin-left: 0;
    flex: 1 0 100%;
  }

  .picpic {
    height: 300px;
    width: 200px;
    z-index: -1;
    top: 20px;
  }
}
@media only screen and (max-width: 426px) {
  .main {
    margin-left: 0;
    flex: 0 0 93%;
  }

  .map-header {
    width: 95%;
  }

  .showmore {
    left: 24%;
  }

  .filter-header {
    margin-left: auto;
    margin: 0 auto;
    width: 95%;
  }

  .filter-buttons {
    width: 89.5%;
    margin: 20px auto;
  }

  .filterButton {
    margin-bottom: 15px;
  }

  .btnBB {
    margin-bottom: 0;
  }

  .spinnermain {
    height: 60vh;
  }

  .err-msg {
    font-size: 26px;
    width: 80%;
    top: 15%;
  }

  .err-sm {
    top: 10%;
    left: 10%;
  }
}

@media only screen and (max-width: 420px) {
  .main {
    margin-left: 0;
    flex: 0 0 92%;
  }
  .map-header {
    width: 92%;
  }
}

@media only screen and (max-width: 377px) {
  .picpic {
    height: 220px;
    width: 150px;
    z-index: -1;
    top: 50px;
  }
  .main {
    margin-left: 0;
    flex: 0 0 95%;
  }
  .filter-buttons {
    width: 90%;
  }
  .filterButton {
    font-size: 15px;
    margin-bottom: 9px;
  }
  .map-header {
    width: 90%;
  }
  .err-msg {
    font-size: 24px;
    width: 80%;
    top: 15%;
  }

  .err-sm {
    top: 5%;
    left: 5%;
  }
}
@media only screen and (max-width: 361px) {
  .main {
    flex: 0 0 96.5%;
  }

  .map-header {
    width: 94%;
  }
}

@media only screen and (min-height: 871px) and (max-height: 901px) {
  .filterButton {
    font-size: 15px;
    margin-bottom: 22px;
  }

  .filter-header {
    padding-bottom: 5px;
    margin-left: 0;
  }
  .picpic {
    top: 75px;
    height: 560px;
    width: 380px;
  }
}

@media only screen and (min-height: 731px) and (max-height: 380px) {
  .picpic {
    height: 220px;
    width: 150px;
    z-index: -1;
    top: 50px;
  }
}
